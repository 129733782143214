import React, { useEffect } from 'react'
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { svgVariants, textVariants, pathVariants } from '../component/motion';
import { motion } from 'framer-motion';
import CTASvg from '../common/CTASvg';



const ThankYou = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <section className='main_section '>
                <div className='main_bg thankyouBg'>

                    <motion.div className='main_heading thank_u' variants={textVariants('up', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <h1>THANK YOU!</h1>
                        <p className='thanktext'>Your message has been received, we will update you shortly. </p>
                        <Link to="/" className='thankyoubutton'>
                            <div className="action active-cursor decor no-transition" style={{ translate: "none", rotate: "none", scale: " none", transform: "translate(0px, 0px)", opacity: '1' }}>
                                <p className='m-0'>Back to home</p>
                                <CTASvg />
                            </div>
                        </Link>
                    </motion.div>

                </div>



            </section>

        </>
    )
}

export default ThankYou