import React, { useState, useEffect } from 'react'
import Banner from '../assets/amenitiesBanner.jpg';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { contactPage } from '../../Data';
import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import { motion } from 'framer-motion';
import villa1 from '../assets/villaSlider3.jpg';
import img1 from '../assets/villa33.jpg';
import bbq from '../assets/bbq.jpg';
import download from '../assets/downloadIcon.jpg';
import { svgVariants, textVariants, pathVariants } from '../component/motion';

import AmentiesTabs from '../component/AmenitiesTabs';

const Amenities = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

    return (
        <>
            <section className='main_section amenities_section'>
                <div className='main_bg amenitiesBg'>
                    <motion.div className='contact_breadcrumbs ' variants={textVariants('left', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <Breadcrumbs separator="|" aria-label="breadcrumb">
                            <Link to="/" >HOME </Link>
                            <span>AMENITIES</span>
                        </Breadcrumbs>
                    </motion.div>
                    <motion.div className='main_heading' variants={textVariants('up', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <h1>AMENITIES</h1>
                    </motion.div>
                </div>

                <AmentiesTabs />


                {/* <div className='video-section '>
                    <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/EngW7tLk6R8?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div> */}

            </section>
        </>
    )
}

export default Amenities