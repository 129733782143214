import React, { useState, useEffect } from 'react'
import Banner from '../assets/villasBanner.jpg';
import { Breadcrumbs } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { contactPage, villaData } from '../../Data';
import villa1 from '../assets/villaSlider3.jpg';
import villa2 from '../assets/villa2.jpg';
import villa3 from '../assets/villa3.jpg';
import villa4 from '../assets/villa4.jpg';

import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import { motion } from 'framer-motion';
import { Card } from "react-bootstrap";
import author from '../assets/author.jpg';
import { svgVariants, textVariants, pathVariants } from '../component/motion';
import CTASvg from '../common/CTASvg';


const Villas = () => {

    const navigate = useNavigate();

    const handleMouseMove = (event) => {
        const r = event.currentTarget.getBoundingClientRect();

        event.currentTarget.style.setProperty('--x', event.clientX - (r.left + Math.floor(r.width / 2)));
        event.currentTarget.style.setProperty('--y', event.clientY - (r.top + Math.floor(r.height / 2)));
    };

    const handleMouseLeave = (event) => {
        event.currentTarget.style.setProperty('--x', 0)
        event.currentTarget.style.setProperty('--y', 0)
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <section className='main_section'>
                <div className='main_bg villas_bg'>
                    <motion.div className='contact_breadcrumbs' variants={textVariants('left', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <Breadcrumbs separator="|" aria-label="breadcrumb">
                            <Link to="/" >HOME </Link>
                            <span>VILLAS</span>
                        </Breadcrumbs>
                    </motion.div>
                    <motion.div className='main_heading' variants={textVariants('up', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <h1>VILLAS</h1>
                    </motion.div>
                </div>

                <section className='villas-section '>

                    <motion.svg className='villaSvg'
                        variants={svgVariants('0%', '0%')}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.1 }}
                        xmlns="http://www.w3.org/2000/svg" width="48.8359375vw" height="26.946875vw" viewBox="0 0 938.48 517.983">
                        <motion.path id="BG_line"
                            variants={pathVariants(2)}
                            data-name="BG line" d="M1991.7,1524.342s22.917,313.426,621.2,308.608c178.841.415,218.378,17.766,265.034,46.193" transform="translate(-2245.472 -947.114) rotate(-11)" fill="none" stroke="#707070" stroke-width="1" />
                    </motion.svg>

                    <motion.svg className='villaSvg2'
                        variants={svgVariants('0%', '0%')}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.1 }}
                        xmlns="http://www.w3.org/2000/svg" width="107.41197916666667vw" height="67.08333333333333vw" viewBox="0 0 2084.569 1288.48">
                        <motion.path id="Path_941"
                            variants={pathVariants(3)}
                            data-name="Path 941" d="M13870.16,1867.331s-173.293,80.683-461.021,89.89-821.95-81.056-1216.282,559.373-384.985,602.008-384.985,602.008" transform="matrix(1, 0.017, -0.017, 1, -11751.108, -2072.66)" fill="none" stroke="#707070" stroke-width="1" />
                    </motion.svg>

                    <motion.svg className='villaSvg3'
                        variants={svgVariants('0%', '0%')}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.1 }}
                        xmlns="http://www.w3.org/2000/svg" width="106.92708333333333vw" height="63.854166666666664vw" viewBox="0 0 2053.859 1226.254">
                        <motion.path id="BG_line"
                            variants={pathVariants(3)}
                            data-name="BG line" d="M1991.7,1524.342s49.684,773.088,1346.735,761.205c387.722,1.023,473.437,43.821,574.585,113.938" transform="translate(-2245.473 -749.609) rotate(-11)" fill="none" stroke="#707070" stroke-width="1" />
                    </motion.svg>


                    <div>


                        {villaData?.map((data, index) => {
                            if ((index + 1) % 2 == 0) {
                                return (
                                    <div className='villas-d d-flex align-items-center justify-content-end'>
                                        <div className='img-d'>
                                            <span className='slide--current'
                                                onMouseMove={handleMouseMove}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <div className="slide__image-wrapper">
                                                    <img src={data.image} style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                        </div>
                                        <div className='right-s right-villa'>
                                            <div className='content-card'>
                                                <Card className=''>

                                                    <div className='content-d'>
                                                        <h3>{data.title}</h3>

                                                        <motion.p>
                                                            {data.content}
                                                        </motion.p>

                                                       
                                                    </div>

                                                    <div className='price-d d-flex align-items-top'>
                                                        <div>
                                                            <span>Rs. {data.price} /Night</span><br></br>
                                                            {data.gst && <span>+ {data.gst}% taxes</span>}
                                                        </div>
                                                        <div>
                                                            <div className='section_four_button_sect' onClick={() => navigate(`/villas/${data.id}`)}>
                                                                <div className="action active-cursor decor no-transition" style={{ translate: "none", rotate: "none", scale: " none", transform: "translate(0px, 0px)", opacity: '1' }}>
                                                                    <p className='m-0'>Know More</p>

                                                                    <CTASvg />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Card>
                                            </div>
                                        </div>
                                    </div>

                                )
                            } else {
                                return (
                                    <div className='villas-d d-flex align-items-center'>
                                        <div className='img-d'>
                                            <span className='slide--current'
                                                onMouseMove={handleMouseMove}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <div className="slide__image-wrapper">
                                                    <img src={data.image} style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                        </div>
                                        <div className='right-s'>
                                            <div className='content-card'>
                                                <Card className=''>

                                                    <div className='content-d'>
                                                        <h3>{data.title}</h3>

                                                        <motion.p>
                                                            {data.content}
                                                        </motion.p>

                                                    </div>

                                                    <div className='price-d d-flex align-items-top'>
                                                        <div>
                                                            <span>Rs. {data.price} /Night</span><br></br>
                                                            {data.gst && <span>+ {data.gst}% taxes</span>}
                                                        </div>
                                                        <div>
                                                            <div className='section_four_button_sect' onClick={() => navigate(`/villas/${data.id}`)}>
                                                                <div className="action active-cursor decor no-transition" style={{ translate: "none", rotate: "none", scale: " none", transform: "translate(0px, 0px)", opacity: '1' }}>
                                                                    <p className='m-0'>Know More</p>

                                                                    <CTASvg />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        })}

                    </div>

                </section>




            </section>

        </>
    )
}

export default Villas