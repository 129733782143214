import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Logo from "../assets/logo.png";
import { FaTimes } from "react-icons/fa";
import Menu from "../assets/Menu.svg";
import { textVariants } from "../component/motion";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import "./header.css";
import { menuItem } from "./MenuList";
import menubg from "../assets/menuBg.jpg";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CTASvg from "../common/CTASvg";

import config from "../config/config";
import Joi from "joi";
import http from "../helpers/http";
const Header = () => {
  const [clicked, setClicked] = useState(false);
  const [handleDrop, setHandleDrop] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "/forms/enquiry";
  let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    first_name: Joi.string().trim().required().label("First Name"),
    last_name: Joi.string().trim().required().label("Last Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .messages({
        "string.pattern.base": `Contact number must have 10 digits.`,
      })
      .required()
      .label("Contact number"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    // let formData = new FormData();
    // for (const [key, data] of Object.entries(
    //   _.omit(values, ["document", "phone"])
    // )) {
    //   formData.append(key, data || "");
    // }

    // if (values.phone) {
    //   formData.append("phone", `${countryCode}${values.phone}`);
    // }
    // if (values.document) {
    //   formData.append("document", values.document);
    // }

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      // navigate("/thank-you");
      setValues({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
      });

      setModalShow(false);
      navigate("/thankyou");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const handleClicked = () => {
    setClicked(!clicked);
    setHandleDrop(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setHandleDrop(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [handleDrop]);

  const ref = useRef();

  const handleMenu = () => {
    if (ref.current.classList.contains("nav-active")) {
      ref.current.classList.remove("nav-active");
      setClicked(false);
      // document.getElementById('root').
      // ref.current.classList.add('translate_x_')
    } else if (!ref.current.classList.contains("nav-active")) {
      ref.current.classList.add("nav-active");
      // ref.current.classList.remove('translate_x_')
      setClicked(true);
    }
  };

  const stickyRef = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header ${
    clicked ? "navbar fixed-top" : "navbar fixed-top position-absolute"
  }`;
  const headerClassRes = `header ${
    sticky && check
      ? "navbar fixed-top sticky"
      : "navbar fixed-top position-absolute"
  }`;
  // const { clientHeight } = ref;

  // const { clientHeight } = ref;

  // const checkChange = (value) => {
  //     setCheck(value);
  // };

  function useStickyHeader(offset = 0) {
    const [stick, setStick] = useState(false);

    const handleScroll = () => {
      setStick(window.scrollY > offset);
    };

    useLayoutEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });

    return stick;
  }

  return (
    <>
      <div ref={ref}>
        <motion.header
          variants={textVariants("down", 0.2)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.5 }}
          // className={clicked ? 'navbar fixed-top' : 'navbar fixed-top position-absolute'}
          className={clicked ? headerClasses : headerClassRes}
          ref={stickyRef}
        >
          <div className="nav_section d-flex justify-content-between align-items-center w-100">
            <div className="navbar__item nav-head_ mb-1" onClick={handleMenu}>
              {" "}
              {clicked ? (
                <>
                  <svg
                    className="menu_ web_menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="0.9895833333333334vw"
                    height="0.9895833333333334vw"
                    viewBox="0 0 18.957 18.958"
                  >
                    <path
                      id="Vector_Stroke_"
                      data-name="Vector (Stroke)"
                      d="M0,2.181,2.181,0l7.3,7.3,7.3-7.3,2.181,2.181-7.3,7.3,7.3,7.3-2.181,2.181-7.3-7.3-7.3,7.3L0,16.777l7.3-7.3Z"
                      fill="#fff"
                    />
                  </svg>
                  <svg
                    className="menu_ mob_menu_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="3.7218666666666667vw"
                    height="3.7218666666666667vw"
                    viewBox="0 0 13.957 13.958"
                  >
                    <path
                      id="Vector_Stroke_"
                      data-name="Vector (Stroke)"
                      d="M0,1.606,1.606,0,6.979,5.373,12.352,0l1.606,1.606L8.584,6.979l5.373,5.373-1.606,1.606L6.979,8.585,1.606,13.958,0,12.352,5.373,6.979Z"
                      fill="#fff"
                    />
                  </svg>
                  {/* <svg fontSize="3.0752333882482152vw" className='menu_' stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" width="3.0752333882482152vw" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"
                                    style={{ color: "#fff" }}></path></svg> */}
                  <span className="menu_text">CLOSE</span>{" "}
                </>
              ) : (
                <>
                  <svg
                    className="menu_ web_menu_icon"
                    id="Group_1"
                    data-name="Group 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="2.9166666666666665vw"
                    height="1.09375vw"
                    viewBox="0 0 56 21"
                  >
                    <rect
                      id="Rectangle_129"
                      data-name="Rectangle 129"
                      width="33"
                      height="4"
                      transform="translate(0 17)"
                      fill="#fff"
                    />
                    <rect
                      id="Rectangle_133"
                      data-name="Rectangle 133"
                      width="56"
                      height="4"
                      fill="#fff"
                    />
                  </svg>
                  <svg
                    className="menu_ mob_menu_icon"
                    id="Group_6966"
                    data-name="Group 6966"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.4vw"
                    height="2.4vw"
                    viewBox="0 0 24 9"
                  >
                    <rect
                      id="Rectangle_129"
                      data-name="Rectangle 129"
                      width="14"
                      height="2"
                      transform="translate(10 7)"
                      fill="#fff"
                    />
                    <rect
                      id="Rectangle_133"
                      data-name="Rectangle 133"
                      width="24"
                      height="2"
                      transform="translate(0)"
                      fill="#fff"
                    />
                  </svg>
                  {/* <img className='menu_' src={Menu} style={{ width: "3.0752333882482152vw", height: "1.1532125205930808vw" }} /> */}
                  <span className="menu_text">MENU</span>{" "}
                </>
              )}
            </div>

            <div className="navbar__item section_hide">
              <Link to="/" onClick={clicked ? handleMenu : null}>
                <img src={Logo} className="logo" />
              </Link>
            </div>
            <div className="navbar__item section_hide book_now_btn">
              <Link to="#" onClick={() => setModalShow(true)}>
                ENQUIRE NOW
              </Link>
            </div>
          </div>
        </motion.header>

        <div className="nav">
          <div
            className="nav__content menuBg"
            style={clicked ? { opacity: "1", visibility: "visible" } : null}
          >
            <ul className="nav__list">
              {menuItem?.map((data) => {
                return (
                  <>
                    <li className="nav__list-item active-nav">
                      <Link
                        end
                        to={data.url}
                        className="effect-shine"
                        onClick={handleMenu}
                      >
                        {data.title}
                      </Link>
                    </li>
                  </>
                );
              })}
              {/* <li className="nav__list-item active-nav"><Link to="/" className="effect-shine" onClick={handleMenu}>Home</Link></li>
                            <li className="nav__list-item"> <Link to="/contact" className="effect-shine" onClick={handleMenu} >Contact</Link></li> */}
            </ul>
          </div>
        </div>
      </div>

      <Paper
        className="book_now_btn_d"
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation showLabels className="book_now_btn_">
          <BottomNavigationAction
            label="ENQUIRE NOW"
            onClick={() => setModalShow(true)}
          />
        </BottomNavigation>
      </Paper>

      <Modal
        show={modalShow}
        centered
        onHide={() => setModalShow(false)}
        dialogClassName="modal_W"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>

        <section className="contact_sec_three modal_form_d">
          <div className="">
            <motion.div
              className="contact_sec_three_heading"
              variants={textVariants("down", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h1>GET IN TOUCH</h1>
            </motion.div>

            <motion.Form
              className="contact_form"
              onSubmit={handleSubmit}
              autoComplete="off"
              variants={textVariants("right", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <Form.Group className="form-group_ ">
                <Form.Label>FIRST NAME*</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.first_name}</span>
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>LAST NAME*</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.last_name}</span>
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>PHONE*</Form.Label>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Your Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
                 <span className="err_msg">{formError.phone}</span>
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>E-MAIL*</Form.Label>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Your e-mail"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                 <span className="err_msg">{formError.email}</span>
              </Form.Group>
              <Form.Group className="w-100">
                <Form.Label>MESSAGE</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="Enter your message here"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.message}</span>
              </Form.Group>

              <button className="contact_form_submit_button" type="submit">
                <div
                  className="action active-cursor decor no-transition"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: " none",
                    transform: "translate(0px, 0px)",
                    opacity: "1",
                  }}
                >
                  <p className="m-0">Send Request</p>

                  <CTASvg />
                </div>
              </button>
            </motion.Form>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default Header;
