import React, { useState, useEffect } from 'react'
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
// import SliderVillas from '../Slider/SliderVillas';
import qts from '../assets/qts.png';
import { Card } from "react-bootstrap";
import author from '../assets/author.jpg';
import { svgVariants, textVariants, pathVariants } from '../component/motion';
import OverviewSlider from '../common/Slider/OverviewSlider';
import { overviewData } from '../../Data';


const Overview = () => {


    const handleMouseMove = (event) => {
        const r = event.currentTarget.getBoundingClientRect();

        event.currentTarget.style.setProperty('--x', event.clientX - (r.left + Math.floor(r.width / 2)));
        event.currentTarget.style.setProperty('--y', event.clientY - (r.top + Math.floor(r.height / 2)));
    };

    const handleMouseLeave = (event) => {
        event.currentTarget.style.setProperty('--x', 0)
        event.currentTarget.style.setProperty('--y', 0)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>

            <section className='main_section'>
                <div className='main_bg overviweBg'>
                    <motion.div className='contact_breadcrumbs' variants={textVariants('left', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <Breadcrumbs separator="|" aria-label="breadcrumb">
                            <Link to="/" >HOME </Link>
                            <span>OVERVIEW</span>
                        </Breadcrumbs>
                    </motion.div>
                    <motion.div className='main_heading' variants={textVariants('up', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <h1>OVERVIEW</h1>
                    </motion.div>
                </div>

                <div style={{ position: 'relative', overflow: 'hidden' }}>
                    <section className='contact_sec_two overview_section'>
                        <div className='contact_svg_image overview_svg_sc' >



                            <motion.svg xmlns="http://www.w3.org/2000/svg" className='overViewSvg1 web_svg' width="94.32291666666667vw" height="51.5625vw" viewBox="0 0 1811.603 990.972"
                                variants={svgVariants('0%', '-30%')}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: false, amount: 0.1 }}

                            >
                                <motion.path id="BG_line" data-name="BG line"
                                    variants={pathVariants(2)}
                                    d="M1991.7,1524.342s44.3,597.06,1200.916,587.883c345.741.79,422.175,33.843,512.371,87.995" transform="translate(-2245.472 -789.305) rotate(-15)" fill="none" stroke="#707070" stroke-width="1" />
                            </motion.svg>

                            <motion.svg xmlns="http://www.w3.org/2000/svg" className='overViewSvg1 mob_svg' width="101.04213333333334vw" height="55.28293333333334vw" viewBox="0 0 378.908 207.311"
                                variants={svgVariants('0%', '-30%')}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: false, amount: 0.1 }}
                            >
                                <motion.path id="BG_line" data-name="BG line"
                                    variants={pathVariants(2)}
                                    d="M1991.7,1524.342s9.26,124.8,251.019,122.881c72.268.165,88.244,7.074,107.1,18.393" transform="translate(-2245.72 -1047.926) rotate(-11)" fill="none" stroke="#707070" stroke-width="0.5" />
                            </motion.svg>



                        </div>

                        <div className=''>
                            <div className='section_three overview_section_three'>
                                <motion.h6 variants={textVariants('up', 0.1)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: false, amount: 0.1 }}
                                    style={{ justifyContent: 'center' }}>{overviewData.title}</motion.h6>
                                <motion.h4 variants={textVariants('left', 0.2)} initial="hidden" whileInView="show"
                                    className='m-0' viewport={{ once: true, amount: 0.1 }}>
                                    NATURE IS NOT<br></br>A PLACE TO VISIT.<br></br>IT IS <span>Home</span>
                                </motion.h4>
                            </div>
                        </div>
                    </section>



                    {/* <div className='overview_svg_two'>
                    <motion.svg variants={svgVariants('0%', '-25%')}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.1 }}
                        width="" height="1474" viewBox="0 0 1440 1474" fill="none" xmlns="http://www.w3.org/2000/svg" className=""><motion.path variants={pathVariants(2)} d="M-53.7576 1301.75C17.2492 1241.32 -22.8129 1138.53 206.043 840.488C316.384 696.789 467.719 566.319 687.733 537.427C907.746 508.535 1407.11 571.27 1449.16 171.833" stroke="#FFD9B6" stroke-opacity="0.2" style={{ strokeWidth: ' 0', strokeDasharray: 'none' }}></motion.path></motion.svg>
                </div> */}

                    <div className='' style={{ position: 'relative' }}>
                        <div className='section_four'>
                            <div className='section_four_content overView_content'>
                                <div className='_content'>
                                    <motion.p variants={textVariants('right', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}>
                                        {/* {aboutsection[0].content} */}
                                        {overviewData.content}
                                    </motion.p>
                                    <div className='section_four_button_sect'>


                                        {/* <div className="action active-cursor decor no-transition" style={{ translate: "none", rotate: "none", scale: " none", transform: "translate(0px, 0px)", opacity: '1' }}>
                                            <p className='m-0'><Link to="/contact">Book your stay</Link></p>
                                            <div className="circle-desc"><svg width="50" height="50" viewport="0 0 25 25" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                                    strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                                                <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                                    strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                                            </svg> <svg width="50" height="50" viewport="0 0 25 25" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg">
                                                    <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                                        strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                                                </svg></div> <svg width="38" height="43" viewBox="0 0 38 43" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg" className="circle-mob">
                                                <path opacity="0.2"
                                                    d="M1 10.6471C4.44118 4.85363 10.5556 1 17.525 1C28.2807 1 37 10.1782 37 21.5C37 32.8218 28.2807 42 17.525 42C10.5556 42 4.44118 38.1464 1 32.3529"
                                                    stroke="currentColor" strokeWidth={0.5} />
                                            </svg>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <motion.svg xmlns="http://www.w3.org/2000/svg"
                            className='overViewSvg web_svg'
                            variants={svgVariants('0%', '0%')}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: false, amount: 0.1 }}
                            width="107.39583333333333vw" height="65.20833333333333vw" viewBox="0 0 2063.021 1252.666">
                            <motion.path
                                variants={pathVariants(2)}
                                id="Path_941" data-name="Path 941" d="M13870.16,1867.331s-173.293,80.683-461.021,89.89-821.95-81.056-1216.282,559.373-384.985,602.008-384.985,602.008" transform="translate(-11807.35 -1866.878)" fill="none" stroke="#707070" stroke-width="1" />
                        </motion.svg>

                        <motion.svg xmlns="http://www.w3.org/2000/svg"
                            className='overViewSvg mob_svg'
                            variants={svgVariants('0%', '0%')}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: false, amount: 0.1 }}
                            width="99.82533333333333vw" height="60.65839999999999vw" viewBox="0 0 374.345 227.469">
                            <motion.path id="Path_941"
                                variants={pathVariants(2)}
                                data-name="Path 941" d="M12181.84,1867.331s-31.426,14.631-83.6,16.3-149.057-14.7-220.566,101.439-69.815,109.171-69.815,109.171" transform="translate(-11807.601 -1867.105)" fill="none" stroke="#707070" stroke-width="0.5" />
                        </motion.svg>


                    </div>


                    <div className=' overview-d d-flex align-items-center justify-items-center'>

                        <span className='slide--current'
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="overview_d_author slide__image-wrapper">
                                <img src={overviewData.founderImage} style={{ width: '100%' }} />
                            </div>
                        </span>


                        <div className='right-s '>
                            <div className='content-card overview_card'>
                                <Card className=''>
                                    <span className='qts'><img src={qts} /></span>
                                    <div className='content-d'>
                                        <motion.p>
                                            {overviewData.about}
                                        </motion.p>
                                    </div>

                                    <div className='author'>
                                        <motion.h6>{overviewData.founderName}</motion.h6>
                                        <p>Founder of Arlington</p>
                                    </div>

                                </Card>
                            </div>
                        </div>

                        <motion.svg xmlns="http://www.w3.org/2000/svg"
                            className="overViewSvg3"
                            variants={svgVariants('0%', '7%')}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: false, amount: 0.1 }}
                            width="94.32291666666667vw" height="51.5625vw" viewBox="0 0 1811.603 990.972">
                            <motion.path
                                variants={pathVariants(2)}
                                id="BG_line" data-name="BG line" d="M1991.7,1524.342s44.3,597.06,1200.916,587.883c345.741.79,422.175,33.843,512.371,87.995" transform="translate(-2245.472 -789.305) rotate(-11)" fill="none" stroke="#707070" stroke-width="1" />
                        </motion.svg>

                    </div>


                    {/* <motion.svg xmlns="http://www.w3.org/2000/svg"
                        className="overViewSvg3"
                        variants={svgVariants('0%', '7%')}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.1 }}
                        width="94.32291666666667vw" height="51.5625vw" viewBox="0 0 1811.603 990.972">
                        <motion.path
                            variants={pathVariants(2)}
                            id="BG_line" data-name="BG line" d="M1991.7,1524.342s44.3,597.06,1200.916,587.883c345.741.79,422.175,33.843,512.371,87.995" transform="translate(-2245.472 -789.305) rotate(-11)" fill="none" stroke="#707070" stroke-width="1" />
                    </motion.svg> */}





                    {/* <div className='villas_section team_slider'>
                        <div className="villas_heading team_slider_h">
                            <h3>MEET OUR TEAM</h3>
                        </div>
                        <OverviewSlider sliderData={overviewSlider} perView={3.5} />
                    </div> */}


                </div>

            </section>





        </>
    )
}

export default Overview