import React, { useState, useEffect, useRef } from 'react'
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { svgVariants, textVariants, pathVariants } from '../component/motion';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { faqData } from '../../Data';

const FAQ = () => {

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
}, [])

  return (
    <>
      <section className='main_section '>
        <div className='main_bg FaqsBg'>
          <motion.div className='contact_breadcrumbs ' variants={textVariants('left', 0.2)}
            initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
            <Breadcrumbs separator="|" aria-label="breadcrumb">
              <Link to="/" >HOME </Link>
              <span>FAQs</span>
            </Breadcrumbs>
          </motion.div>
          <motion.div className='main_heading' variants={textVariants('up', 0.2)}
            initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
            <h1>FAQs</h1>
          </motion.div>
        </div>


        <div className='Amenities-inner faq-s'>
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="46.22447916666667vw"
            height="25.520833333333332vw"
            viewBox="0 0 888.34 490.315"
            className="singleVillaSvg"
            variants={svgVariants('0%', '0%')}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
          >
            <motion.path
              id="BG_line"
              data-name="BG line"
              d="M1991.7,1524.342s21.692,296.665,587.978,292.1c169.278.393,206.7,16.816,250.861,43.723"
              transform="translate(-2245.472 -956.157) rotate(-11)"
              fill="none"
              stroke="#707070"
              stroke-width="1"
              variants={pathVariants(3)}
            />
          </motion.svg>

          <div className='content_d_'>
            {faqData?.map((data) => {
              return (
                <>
                  <Accordion expanded={expanded === `panel${data.id}`} onChange={handleChange(`panel${data.id}`)} className="accordion-d">
                    <AccordionSummary
                      expandIcon={expanded === `panel${data.id}` ? <HiMinusSm className='expand_icon' /> : <HiPlusSm className='expand_icon' />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header" className='accordion-summary'
                    >
                      <h2>
                        {data.title}
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails className='accordion-details'>
                      <p>
                        {data.content}
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </>
              )
            })}

          </div>

          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="108.54166666666667vw"
            height="67.10833333333333vw"
            viewBox="0 0 2084.569 1288.48"
            className="singleVillaSvg2 faqSvg"
            variants={svgVariants('0%', '0%')}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
          >
            <motion.path
              id="Path_941"
              data-name="Path 941"
              d="M13870.16,1867.331s-173.293,80.683-461.021,89.89-821.95-81.056-1216.282,559.373-384.985,602.008-384.985,602.008"
              transform="matrix(1, 0.017, -0.017, 1, -11751.108, -2072.66)"
              fill="none"
              stroke="#707070"
              stroke-width="1"
              variants={pathVariants(3)}
            />
          </motion.svg>

        </div>

      </section>

    </>
  )
}

export default FAQ