import React from 'react';
import { Route, Router, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Frontend from './web/Frontend';
import Home from './web/pages/Home';
import Contact from './web/pages/Contact';

import Overview from './web/pages/Overview';
import Location from './web/pages/Location';
import Villas from './web/pages/Villas';
import SingleVilla from './web/pages/SingleVilla';
import Amenities from './web/pages/Amenities';
import PrivacyPolicies from './web/pages/PrivacyPolicies';
import TermsAndConditions from './web/pages/TermsAndConditions';
import ThankYou from './web/pages/ThankYou';
import FAQ from './web/pages/FAQ';
import Error from './web/common/Error';

function App() {

  return (
    <>
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Frontend />}>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/contact' element={<Contact />} />
              <Route exact path='/overview' element={<Overview />} />
              <Route exact path='/location' element={<Location />} />
              <Route exact path='/villas' element={<Villas />} />
              <Route exact path='/villas/:id' element={<SingleVilla />} />
              <Route exact path='/amenities' element={<Amenities />} />
              <Route exact path='/privacy-policies' element={<PrivacyPolicies />} />
              <Route exact path='/terms-and-conditions' element={<TermsAndConditions />} />
              <Route exact path='/thankyou' element={<ThankYou />} />
              <Route exact path='/faq' element={<FAQ />} />
              <Route exact path='*' element={<Error />} />

            </Route>
          </Routes>
        </BrowserRouter>
      </React.Fragment>

    </>
  );
}

export default App;
