import React from 'react'
import { FaYoutube, FaTwitter, FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <>
            <footer id="footer">
                <div className='foooter_main_sec'>
                    <div className='footer-s'>
                        <div className="lh-footer">
                            <div className="logo">
                                <a href="#"></a>
                            </div>
                            <h4>Contact Us</h4>
                            <p className='number_d' ><a href='tel:+91 093229 83811'>+91 093229 83811</a></p>

                            <div>
                                <h4>Email address</h4>
                                <p className='email_d'><a href="mailto:contact@arlington.com">contact@arlington.com</a></p>
                            </div>
                        </div>

                        <div className="mid-footer">
                            <div>
                                <h4>Quick Links</h4>
                                <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>

                                    <li><Link to="/overview">Overview</Link></li>
                                    <li><Link to="/villas">Villas</Link></li>
                                    <li><Link to="/amenities">Amenities</Link></li>
                                    <li><Link to="/location">Location</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="rh-footer">
                            <h4>Information</h4>
                            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                                <li><Link to="/faq">Faq</Link></li>
                                <li><Link to="/terms-and-conditions">T&C</Link></li>
                                <li><Link to="/privacy-policies">Privacy Policy</Link></li>

                            </ul>
                        </div>
                    </div>
                    <hr></hr>
                    <div className='copyright-d pt-3 pb-3'>
                        <h6>All Rights reserved 2023 by <Link to="/">Arlington</Link></h6>
                        <div className='d-flex g-3' style={{ gap: '30px' }}>
                            <Link to="https://www.youtube.com/" target='_blank'>
                                <FaYoutube fontSize={'1.1723958333333333vw'} />
                            </Link>
                            <Link to="https://twitter.com/" target='_blank'>
                                <FaTwitter fontSize={'1.1723958333333333vw'} />
                            </Link>
                            <Link to="https://www.facebook.com/" target='_blank'>
                                <FaFacebookF fontSize={'1.1723958333333333vw'} />
                            </Link>
                            <Link to="https://www.instagram.com/" target='_blank'>
                                <FaInstagram fontSize={'1.1723958333333333vw'} />
                            </Link>
                            <Link to="https://www.linkedin.com/" target='_blank'>
                                <FaLinkedinIn fontSize={'1.1723958333333333vw'} />
                            </Link>
                        </div>
                        <h6 className='mob_'>All Rights reserved 2023 by <Link to="/">Arlington</Link></h6>
                        <h6>Crafted by <Link to="https://www.togglehead.in/" target='_blank'>Togglehead</Link></h6>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
