import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { fixedContainer, slideIn, textVariants, svgVariants, pathVariants } from './motion';
import { tabOneSectionOne, tabOneSectionTwo, amenitiesData } from '../../Data';
import { Link } from 'react-router-dom';
import image1 from '../assets/Amenities/Arlington Classic 6BHK.jpg'
import image2 from '../assets/Amenities/Arlington Contemporary 5BHK.jpg'
import image3 from '../assets/Amenities/Arlington Meadows 1 3BHK.jpg'
import image4 from '../assets/Amenities/Arlington Meadows 2 4BHK.jpg'


import img1 from '../assets/villa33.jpg';
import bbq from '../assets/bbq.jpg';
import indoorGames from '../assets/indoorGames.jpg'
import download from '../assets/downloadIcon.jpg';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import amenitiesVideoBg from '../assets/amenitiesVideoBg.jpg';
import { IconButton } from '@mui/material';
import pdf from '../assets/dummyPDf.pdf'


export default function AmentiesTabs({ tabData }) {

    const [value, setValue] = React.useState(1);
    const [data, setData] = useState(tabOneSectionOne);
    const [sectionTwo, setSetionTwo] = useState(tabOneSectionTwo);
    const [isPlay, setIsPlay] = useState(false);



    const handleChange = (event, newValue) => {
        setValue(newValue);
        
    };


    const [activeSubTitleOne, setActiveSubTitleOne] = useState(1);
    const [activeSubTitleTwo, setActiveSubTitleTwo] = useState(1);
    const [activeSubTitleThree, setActiveSubTitleThree] = useState(1);
    const [activeSubTitleFour, setActiveSubTitleFour] = useState(1);

    const handleMouseMove = (event) => {
        const r = event.currentTarget.getBoundingClientRect();

        event.currentTarget.style.setProperty('--x', event.clientX - (r.left + Math.floor(r.width / 2)));
        event.currentTarget.style.setProperty('--y', event.clientY - (r.top + Math.floor(r.height / 2)));
    };

    const handleMouseLeave = (event) => {
        event.currentTarget.style.setProperty('--x', 0)
        event.currentTarget.style.setProperty('--y', 0)
    };

    const ScrollButtonComponent = ({ direction, onClick }) => (
        <IconButton onClick={onClick}>
            {direction === 'left' ? <svg xmlns="http://www.w3.org/2000/svg" width="3.4895833333333335vw" height="3.4895833333333335vw" viewBox="0 0 67 67">
                <g id="Group_633" data-name="Group 633" transform="translate(-921.5 -2783.5)">
                    <g id="Group_630" data-name="Group 630" transform="translate(940.523 2827.915) rotate(-90)">
                        <path id="Path_922" data-name="Path 922" d="M0,11.438S10.134,6.613,11.217,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="1" />
                        <path id="Path_923" data-name="Path 923" d="M11.526,11.393S.045,4.638,0,0" transform="translate(11.305 0.044)" fill="none" stroke="#fff" stroke-width="1" />
                        <path id="Path_924" data-name="Path 924" d="M0,27.865V0" transform="translate(11.307 0.088)" fill="none" stroke="#fff" stroke-width="1" />
                    </g>
                    <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(921.5 2783.5)" fill="none" stroke="#fff" stroke-width="1">
                        <circle cx="33.5" cy="33.5" r="33.5" stroke="none" />
                        <circle cx="33.5" cy="33.5" r="33" fill="none" />
                    </g>
                </g>
            </svg> : <svg id="Group_668" data-name="Group 668" xmlns="http://www.w3.org/2000/svg" width="3.4895833333333335vw" height="3.4895833333333335vw" viewBox="0 0 67 67">
                <g id="Group_630" data-name="Group 630" transform="translate(20.023 44.415) rotate(-90)">
                    <path id="Path_922" data-name="Path 922" d="M0,0S10.134,4.825,11.217,11.438" transform="translate(0 16.516)" fill="none" stroke="#fff" stroke-width="1" />
                    <path id="Path_923" data-name="Path 923" d="M11.526,0S.045,6.755,0,11.393" transform="translate(11.305 16.516)" fill="none" stroke="#fff" stroke-width="1" />
                    <path id="Path_924" data-name="Path 924" d="M0,0V27.865" transform="translate(11.307 0)" fill="none" stroke="#fff" stroke-width="1" />
                </g>
                <g id="Ellipse_3" data-name="Ellipse 3" fill="none" stroke="#fff" stroke-width="1">
                    <circle cx="33.5" cy="33.5" r="33.5" stroke="none" />
                    <circle cx="33.5" cy="33.5" r="33" fill="none" />
                </g>
            </svg>}
        </IconButton>
    );


    return (
        <div>
            <Box style={{ position: 'relative', zIndex: '9' }} >
                <TabContext value={value} scrollButtons variant="scrollable" >
                    <div className='amenites_tabss'>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            {/* <TabList onChange={handleChange} aria-label="lab API tabs example" > */}
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                // ScrollButtonComponent={ScrollButtonComponent}
                                // aria-label="visible arrows tabs example"
                                sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                }}
                            // sx={{ 'svg': { opacity: 0.3 } }}
                            >
                                <Tab label="Arlington Classic" value={1} />
                                <Tab label="Arlington Meadows - I" value={2} />
                                <Tab label="Arlington Meadows - II" value={3} />
                                <Tab label="Arlington Contemporary" value={4} />
                            </Tabs>
                            {/* </TabList> */}
                        </Box>

                        <motion.svg xmlns="http://www.w3.org/2000/svg" className='amenities_svgLine'
                            variants={svgVariants('0%', '0%')}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: false, amount: 0.1 }}
                            width="88.28373333333333vw" height="48.653333333333336vw" viewBox="0 0 331.064 182.747">
                            <motion.path id="BG_line" data-name="BG line"
                                variants={pathVariants(3)}
                                d="M1991.7,1524.342s8.082,110.525,219.056,108.826c63.066.146,77.008,6.265,93.46,16.289" transform="translate(-2245.72 -1056.627) rotate(-11)" fill="none" stroke="#707070" stroke-width="0.5" />
                        </motion.svg>
                    </div>

                    {amenitiesData?.map((data) => {
                        return (
                            <>
                                <TabPanel value={data.id}>
                                    <div className='amenties_main_sec Amenities-s'>
                                        <div className='Amenities-d'>
                                            <div className='Amenities-inner_d d-flex'>
                                                <div className='img-s'>
                                                    <div className='img-d h-100'>
                                                        <img src={data.image} style={{ objectFit: "cover" }} />
                                                    </div>

                                                </div>
                                                <div className=''>
                                                    <div className='content-d'>
                                                        <h1 className='heading'>
                                                            {data.title}
                                                        </h1>
                                                        <div className='content'>
                                                            <p>
                                                                {data.content}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Amenities-inner amenities_relaxin'>
                                        {data.amenities.map((amenity, index) => {
                                            if ((index + 1) % 2 == 0) {
                                                return (
                                                    <div className='Amenities-inner-d odd_d d-flex align-items-center justify-content-between '>
                                                        <div className='content-d'>
                                                            <h1 className='heading'>
                                                                {amenity.title}
                                                            </h1>
                                                            <div>

                                                                <motion.p>
                                                                    {amenity.content}
                                                                </motion.p>

                                                                <span className='d-flex align-items-center' >

                                                                </span>
                                                            </div>

                                                        </div>
                                                        <div className='img-d'>
                                                            <span className='slide--current'
                                                                onMouseMove={handleMouseMove}
                                                                onMouseLeave={handleMouseLeave}
                                                            >
                                                                <div className="slide__image-wrapper">
                                                                    <img src={amenity.image} style={{ width: '100%' }} />
                                                                </div>
                                                            </span>

                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className='Amenities-inner-d d-flex align-items-center'>
                                                        <div className='img-d'>
                                                            <span className='slide--current'
                                                                onMouseMove={handleMouseMove}
                                                                onMouseLeave={handleMouseLeave}
                                                            >
                                                                <div className="slide__image-wrapper">
                                                                    <img src={amenity.image} style={{ width: '100%' }} />
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div className='content-d'>
                                                            <h1 className='heading'>
                                                                {amenity.title}
                                                            </h1>
                                                            <div>

                                                                <motion.p>
                                                                    {amenity.content}
                                                                </motion.p>

                                                                <span className='d-flex align-items-center' >

                                                                </span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                )
                                            }

                                        })}
                                    </div>

                                    <motion.svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103.85416666666667vw"
                                        height="63.125vw"
                                        viewBox="0 0 2063.021 1252.666"
                                        className="amenitiesSvg"
                                        variants={svgVariants('0%', '0%')}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: false, amount: 0.1 }}
                                    >

                                        <motion.path
                                            id="Path_941"
                                            data-name="Path 941"
                                            d="M13870.16,1867.331s-173.293,80.683-461.021,89.89-821.95-81.056-1216.282,559.373-384.985,602.008-384.985,602.008"
                                            transform="translate(-11807.35 -1866.878)"
                                            fill="none"
                                            stroke="#707070"
                                            stroke-width="1"
                                            variants={pathVariants(3)}

                                        />
                                    </motion.svg>


                                    <motion.svg className='amenitiesSvg2'
                                        variants={svgVariants('0%', '0%')}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: false, amount: 0.1 }}
                                        xmlns="http://www.w3.org/2000/svg" width="100.05208333333333vw" height="26.822916666666668vw" viewBox="0 0 1920.874 515.238">
                                        <motion.path id="Path_1001"
                                            variants={pathVariants(3)}
                                            data-name="Path 1001" d="M1990,6086.286s242.764-40.007,305.918-98.094c4.722-6.523,4.972-20.463,4.722-20.89-2.381-10.754-7.373-17.161-21.509-16.5-13.945.351-21.919,17.158-21.919,17.158-1.231,12.4-4.136,11.793,14.733,48.655,63.265,123.586,770.646-233.41,770.646-233.41s622.348-344.277,868.051-154.483" transform="translate(-1989.951 -5571.343)" fill="none" stroke="#fff" stroke-width="0.6" />
                                    </motion.svg>

                                    <div className='video_section' style={{ backgroundImage: `url(${amenitiesVideoBg})` }}>
                                        {!isPlay ?
                                            <div className='video_c' onClick={() => setIsPlay(!isPlay)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="5.052083333333333vw" height="5.052083333333333vw" viewBox="0 0 97 97">
                                                    <circle id="Ellipse_21" data-name="Ellipse 21" cx="48.5" cy="48.5" r="48.5" fill="#fff" opacity="0.31" />
                                                </svg>
                                                <svg style={{ position: 'absolute', top: '2vw' }} xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
                                                    <g id="Polygon_2" data-name="Polygon 2" transform="translate(16) rotate(90)" fill="#fff">
                                                        <path d="M 17.14507484436035 15.5 L 0.8549256324768066 15.5 L 9 1.019867777824402 L 17.14507484436035 15.5 Z" stroke="none" />
                                                        <path d="M 9 2.039735794067383 L 1.709850311279297 15 L 16.2901496887207 15 L 9 2.039735794067383 M 9 0 L 18 16 L 0 16 L 9 0 Z" stroke="none" fill="#707070" />
                                                    </g>
                                                </svg>
                                                <span className='watch_'>Watch Video</span>
                                            </div> :
                                            <iframe width="100%" height="100%" src={data.videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        }

                                    </div>

                                </TabPanel>
                            </>
                        )
                    })}
                </TabContext>
            </Box>
        </div >
    );
}

