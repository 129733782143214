import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tabs, tabsClasses } from '@mui/material';
import { motion } from 'framer-motion';
import { fixedContainer, slideIn, textVariants } from './motion';
import { tabOneSectionOne, tabOneSectionTwo, ExclusiveIndulgences } from '../../Data';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import TabSlider from '../common/Slider/TabSlider';
import { IconButton } from '@mui/material';



export default function LabTabs({ tabData }) {

    const [value, setValue] = React.useState('1');
    const [tabsData, setTabsData] = useState([
        {
            firstTab: 1,
            secondTab: 1,
            thirdTab: 1,
            fourthTab: 1,
        }
    ]);
    const [data, setData] = useState(tabOneSectionOne);
    const [sectionTwo, setSetionTwo] = useState(tabOneSectionTwo);



    const handleChange = (event, newValue) => {
        setValue(newValue);

    };


    const handleNextTab = () => {
 
        setValue((prevTab) => {
            let newData = parseInt(prevTab) + 1
            return (
                newData.toString()
            )
        });
    };

    const handlePrevTab = () => {
       
        setValue((prevTab) => {
            let newData = parseInt(prevTab) - 1
            return (
                newData.toString()
            )
        });
    };

    const [activeSubTitleOne, setActiveSubTitleOne] = useState(1);
    const [activeSubTitleTwo, setActiveSubTitleTwo] = useState(1);
    const [activeSubTitleThree, setActiveSubTitleThree] = useState(1);
    const [activeSubTitleFour, setActiveSubTitleFour] = useState(1);




    const [expanded, setExpanded] = React.useState([{
        firstTab: 'panel11',
        secondTab: 'panel21',
        thirdTab: 'panel31',
        fourthTab: 'panel41',
    }]);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const handleMouseMove = (event) => {
        const r = event.currentTarget.getBoundingClientRect();

        event.currentTarget.style.setProperty('--x', event.clientX - (r.left + Math.floor(r.width / 2)));
        event.currentTarget.style.setProperty('--y', event.clientY - (r.top + Math.floor(r.height / 2)));
    };

    const handleMouseLeave = (event) => {
        event.currentTarget.style.setProperty('--x', 0)
        event.currentTarget.style.setProperty('--y', 0)
    };


    // handle Accordion and tab section 
    const handleTabs = (dataId, imgId) => {
        let updateData = [...tabsData];
        let updateExpanded = [...expanded];
        if (dataId == 1) {
            updateData[0].firstTab = imgId;
            updateExpanded[0].firstTab = `panel${dataId}${imgId}`

        } else if (dataId == 2) {
            updateData[0].secondTab = imgId;
            updateExpanded[0].secondTab = `panel${dataId}${imgId}`
        } else if (dataId == 3) {
            updateData[0].thirdTab = imgId;
            updateExpanded[0].thirdTab = `panel${dataId}${imgId}`
        } else if (dataId == 4) {
            updateData[0].fourthTab = imgId;
            updateExpanded[0].fourthTab = `panel${dataId}${imgId}`
        }

        let updatedData = updateData
        let updatedExpanded = updateExpanded
        setTabsData(updatedData);
        setExpanded(updatedExpanded);
        // handleChangeAccordion(`panel${dataId}${imgId}`);


    }




    const ScrollButtonComponent = ({ direction, onClick }) => (
        <IconButton onClick={onClick}>
            {direction === 'left' ? <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                <g id="Group_6970" data-name="Group 6970" transform="translate(-921 -2783)">
                    <g id="Group_630" data-name="Group 630" transform="translate(927.652 2809.36) rotate(-90)">
                        <path id="Path_922" data-name="Path 922" d="M0,8.877S7.865,5.132,8.705,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="1" />
                        <path id="Path_923" data-name="Path 923" d="M8.945,8.843S.035,3.6,0,0" transform="translate(8.774 0.034)" fill="none" stroke="#fff" stroke-width="1" />
                        <path id="Path_924" data-name="Path 924" d="M0,21.627V0" transform="translate(8.775 0.069)" fill="none" stroke="#fff" stroke-width="1" />
                    </g>
                    <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(921 2783)" fill="none" stroke="#fff" stroke-width="1">
                        <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                        <circle cx="17.5" cy="17.5" r="17" fill="none" />
                    </g>
                </g>
            </svg> : <svg id="Group_7024" data-name="Group 7024" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                <g id="Group_630" data-name="Group 630" transform="translate(6.652 26.36) rotate(-90)">
                    <path id="Path_922" data-name="Path 922" d="M0,0S7.865,3.744,8.705,8.877" transform="translate(0 12.818)" fill="none" stroke="#fff" stroke-width="1" />
                    <path id="Path_923" data-name="Path 923" d="M8.945,0S.035,5.243,0,8.843" transform="translate(8.774 12.818)" fill="none" stroke="#fff" stroke-width="1" />
                    <path id="Path_924" data-name="Path 924" d="M0,0V21.627" transform="translate(8.775 0)" fill="none" stroke="#fff" stroke-width="1" />
                </g>
                <g id="Ellipse_3" data-name="Ellipse 3" fill="none" stroke="#fff" stroke-width="1">
                    <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                    <circle cx="17.5" cy="17.5" r="17" fill="none" />
                </g>
            </svg>}
        </IconButton>
    );


    return (
        <div>

            <Box style={{ zIndex: '999' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <div className='tab_section_'>
                            <svg
                                onClick={value > 1 ? () => handlePrevTab() : null}
                                xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                                <g id="Group_6970" data-name="Group 6970" transform="translate(-921 -2783)">
                                    <g id="Group_630" data-name="Group 630" transform="translate(927.652 2809.36) rotate(-90)">
                                        <path id="Path_922" data-name="Path 922" d="M0,8.877S7.865,5.132,8.705,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="1" />
                                        <path id="Path_923" data-name="Path 923" d="M8.945,8.843S.035,3.6,0,0" transform="translate(8.774 0.034)" fill="none" stroke="#fff" stroke-width="1" />
                                        <path id="Path_924" data-name="Path 924" d="M0,21.627V0" transform="translate(8.775 0.069)" fill="none" stroke="#fff" stroke-width="1" />
                                    </g>
                                    <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(921 2783)" fill="none" stroke="#fff" stroke-width="1">
                                        <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                                        <circle cx="17.5" cy="17.5" r="17" fill="none" />
                                    </g>
                                </g>
                            </svg>
                            <Tabs onChange={handleChange}
                                value={value}
                            // variant="scrollable"
                            // scrollButtons="auto"
                            // aria-label="scrollable auto tabs example"
                            // ScrollButtonComponent={ScrollButtonComponent}

                            // aria-label="visible arrows tabs example"
                            // sx={{
                            //     [`& .${tabsClasses.scrollButtons}`]: {
                            //         '&.Mui-disabled': { opacity: 0.3 },
                            //     },
                            // }}
                            >
                                <Tab label="Arlington Classic" value="1" />
                                <Tab label="Arlington Meadows - I" value="2" />
                                <Tab label="Arlington Meadows - II" value="3" />
                                <Tab label="Arlington Contemporary" value="4" />
                            </Tabs>
                            <svg
                                onClick={value < 4 ? () => handleNextTab() : null}
                                id="Group_7024" data-name="Group 7024" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                                <g id="Group_630" data-name="Group 630" transform="translate(6.652 26.36) rotate(-90)">
                                    <path id="Path_922" data-name="Path 922" d="M0,0S7.865,3.744,8.705,8.877" transform="translate(0 12.818)" fill="none" stroke="#fff" stroke-width="1" />
                                    <path id="Path_923" data-name="Path 923" d="M8.945,0S.035,5.243,0,8.843" transform="translate(8.774 12.818)" fill="none" stroke="#fff" stroke-width="1" />
                                    <path id="Path_924" data-name="Path 924" d="M0,0V21.627" transform="translate(8.775 0)" fill="none" stroke="#fff" stroke-width="1" />
                                </g>
                                <g id="Ellipse_3" data-name="Ellipse 3" fill="none" stroke="#fff" stroke-width="1">
                                    <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                                    <circle cx="17.5" cy="17.5" r="17" fill="none" />
                                </g>
                            </svg>
                        </div>

                    </Box>


                 


                    {/* first tab start */}


                    <TabPanel value="1">
                        <div className='section_mainone'>

                            <>
                                <motion.p variants={textVariants('left', 0.2)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    transition={{ duration: 0.5 }}>{ExclusiveIndulgences[0].heading}</motion.p>
                                <div className='d-flex exclusive_div'>
                                    <motion.h4 variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}


                                        transition={{ duration: 0.5 }}>{ExclusiveIndulgences[0].title}</motion.h4>
                                    <motion.p className='' variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}


                                        transition={{ duration: 0.5 }}>{ExclusiveIndulgences[0].content.substring(0, 110)}...</motion.p>



                                    {ExclusiveIndulgences[0]?.category?.filter(data => data.id == tabsData[0]?.firstTab).map((two) => {
                                        return (
                                            <>
                                                <div className='right_img-d web-d'
                                                >
                                                    <span className='slide--current'
                                                        onMouseMove={handleMouseMove}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <div className="slide__image-wrapper">
                                                            <img className='right_img' variant="top" src={two.image1}

                                                            />
                                                        </div>
                                                    </span>


                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                            </>


                        </div>
                        <div className='section_maintwo'>
                            <div className='d-flex justify-content-between image-section' style={{ gap: '100px' }}>
                                {ExclusiveIndulgences[0]?.category?.filter(data => data.id == tabsData[0]?.firstTab).map((two) => {
                                    return (

                                        <>

                                            <div className='web-d'>

                                                <span className='slide--current'
                                                    onMouseMove={handleMouseMove}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <div className="slide__image-wrapper">
                                                        <img className='left_img' variant="top" src={two.image2} style={{}} />
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    )
                                })}

                                <div className="links-list">
                                    {ExclusiveIndulgences[0]?.category?.map((two) => {
                                        return (
                                            <>

                                                <div className='tab_accordion__'>
                                                    <Link onClick={() => handleTabs(ExclusiveIndulgences[0]?.id, two.id)}
                                                        className={tabsData[0]?.firstTab == two.id ? "h3 links-list-active active-cursor active" : "h3 links-list-active active-cursor"}>
                                                        <div className='d-flex gap-2 hide_show_hover'>
                                                            <motion.h6 variants={textVariants('left', 0.2)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }}


                                                                transition={{ duration: 0.5 }}>{two.subtitle}</motion.h6>
                                                            <div className='right_arrow_svg'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="2.4848958333333333vw" height="3.0421875vw" viewBox="0 0 58.652 49.111">
                                                                    <g id="Group_630" data-name="Group 630" transform="translate(58.41 0.645) rotate(90)">
                                                                        <path id="Path_922" data-name="Path 922" d="M0,23.9S21.176,13.818,23.438,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="3" />
                                                                        <path id="Path_923" data-name="Path 923" d="M24.084,23.807S.094,9.692,0,0" transform="translate(23.622 0.092)" fill="none" stroke="#fff" stroke-width="3" />
                                                                        <path id="Path_924" data-name="Path 924" d="M0,58.226V0" transform="translate(23.626 0.185)" fill="none" stroke="#fff" stroke-width="3" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>


                                                <div className='tab_accordion_'>
                                                    <Accordion expanded={expanded[0]?.firstTab === `panel${ExclusiveIndulgences[0]?.id}${two.id}`}

                                                    >
                                                        <AccordionSummary

                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                        >
                                                            <Link onClick={() => handleTabs(ExclusiveIndulgences[0]?.id, two.id)}
                                                                className={tabsData[0]?.firstTab == two.id ? "h3 links-list-active active-cursor active" : "h3 links-list-active active-cursor"}>
                                                                <div className='d-flex gap-2 hide_show_hover'>
                                                                    <motion.h6 variants={textVariants('left', 0.2)}
                                                                        initial="hidden"
                                                                        whileInView="show"
                                                                        viewport={{ once: true, amount: 0.1 }}


                                                                        transition={{ duration: 0.5 }}>{two.subtitle}</motion.h6>
                                                                    <div className='right_arrow_svg'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="2.4848958333333333vw" height="3.0421875vw" viewBox="0 0 58.652 49.111">
                                                                            <g id="Group_630" data-name="Group 630" transform="translate(58.41 0.645) rotate(90)">
                                                                                <path id="Path_922" data-name="Path 922" d="M0,23.9S21.176,13.818,23.438,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                <path id="Path_923" data-name="Path 923" d="M24.084,23.807S.094,9.692,0,0" transform="translate(23.622 0.092)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                <path id="Path_924" data-name="Path 924" d="M0,58.226V0" transform="translate(23.626 0.185)" fill="none" stroke="#fff" stroke-width="3" />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </AccordionSummary>
                                                        <AccordionDetails className='p-0'>
                                                            <TabSlider sliderData={ExclusiveIndulgences[0]?.category[two.id - 1]} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>

                                            </>


                                        )
                                    })}
                                </div >
                            </div>
                        </div>

                    </TabPanel>



                    {/* first tab end */}


                    <TabPanel value="2">
                        <div className='section_mainone'>

                            <>
                                <motion.p variants={textVariants('left', 0.2)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    transition={{ duration: 0.5 }}>{ExclusiveIndulgences[1].heading}</motion.p>
                                <div className='d-flex exclusive_div'>
                                    <motion.h4 variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        transition={{ duration: 0.5 }}>{ExclusiveIndulgences[1].title}</motion.h4>
                                    <motion.p className='' variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        transition={{ duration: 0.5 }}>{ExclusiveIndulgences[1].content.substring(0, 110)}...</motion.p>


                                    {ExclusiveIndulgences[1]?.category?.filter(data => data.id == tabsData[0]?.secondTab).map((two) => {
                                        return (
                                            <>
                                                <div className='right_img-d web-d'

                                                >
                                                    <span className='slide--current'
                                                        onMouseMove={handleMouseMove}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <div className="slide__image-wrapper">
                                                            <img className='right_img' variant="top" src={two.image1}

                                                            />
                                                        </div>
                                                    </span>

                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </>

                        </div>
                        <div className='section_maintwo'>
                            <div className='d-flex justify-content-between image-section' style={{ gap: '100px' }}>
                                {ExclusiveIndulgences[1]?.category?.filter(data => data.id == tabsData[0]?.secondTab).map((two) => {
                                    return (

                                        <>

                                            <div className='web-d'>

                                                <span className='slide--current'
                                                    onMouseMove={handleMouseMove}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <div className="slide__image-wrapper">
                                                        <img className='left_img' variant="top" src={two.image2} style={{}} />
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    )
                                })}

                                <div className="links-list">
                                    {ExclusiveIndulgences[1]?.category?.map((two) => {
                                        return (
                                            <>

                                                <div className='tab_accordion__'>
                                                    <Link onClick={() => handleTabs(ExclusiveIndulgences[1]?.id, two.id)}
                                                        className={tabsData[0]?.secondTab == two.id ? "h3 links-list-active active-cursor active" : "h3 links-list-active active-cursor"}>
                                                        <div className='d-flex gap-2 hide_show_hover'>
                                                            <motion.h6 variants={textVariants('left', 0.2)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }}


                                                                transition={{ duration: 0.5 }}>{two.subtitle}</motion.h6>
                                                            <div className='right_arrow_svg'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="2.4848958333333333vw" height="3.0421875vw" viewBox="0 0 58.652 49.111">
                                                                    <g id="Group_630" data-name="Group 630" transform="translate(58.41 0.645) rotate(90)">
                                                                        <path id="Path_922" data-name="Path 922" d="M0,23.9S21.176,13.818,23.438,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="3" />
                                                                        <path id="Path_923" data-name="Path 923" d="M24.084,23.807S.094,9.692,0,0" transform="translate(23.622 0.092)" fill="none" stroke="#fff" stroke-width="3" />
                                                                        <path id="Path_924" data-name="Path 924" d="M0,58.226V0" transform="translate(23.626 0.185)" fill="none" stroke="#fff" stroke-width="3" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className='tab_accordion_'>
                                                    <Accordion expanded={expanded[0]?.secondTab === `panel${ExclusiveIndulgences[1]?.id}${two.id}`}

                                                    >
                                                        <AccordionSummary

                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                        >
                                                            <Link onClick={() => handleTabs(ExclusiveIndulgences[1]?.id, two.id)}
                                                                className={tabsData[0]?.secondTab == two.id ? "h3 links-list-active active-cursor active" : "h3 links-list-active active-cursor"}>
                                                                <div className='d-flex gap-2 hide_show_hover'>
                                                                    <motion.h6 variants={textVariants('left', 0.2)}
                                                                        initial="hidden"
                                                                        whileInView="show"
                                                                        viewport={{ once: true, amount: 0.1 }}


                                                                        transition={{ duration: 0.5 }}>{two.subtitle}</motion.h6>
                                                                    <div className='right_arrow_svg'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="2.4848958333333333vw" height="3.0421875vw" viewBox="0 0 58.652 49.111">
                                                                            <g id="Group_630" data-name="Group 630" transform="translate(58.41 0.645) rotate(90)">
                                                                                <path id="Path_922" data-name="Path 922" d="M0,23.9S21.176,13.818,23.438,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                <path id="Path_923" data-name="Path 923" d="M24.084,23.807S.094,9.692,0,0" transform="translate(23.622 0.092)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                <path id="Path_924" data-name="Path 924" d="M0,58.226V0" transform="translate(23.626 0.185)" fill="none" stroke="#fff" stroke-width="3" />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </AccordionSummary>
                                                        <AccordionDetails className='p-0'>
                                                            <TabSlider sliderData={ExclusiveIndulgences[1]?.category[two.id - 1]} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>

                                            </>

                                        )
                                    })}
                                </div >
                            </div>
                        </div>

                    </TabPanel>

                    <TabPanel value="3">
                        <div className='section_mainone'>

                            <>
                                <motion.p variants={textVariants('left', 0.2)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}


                                    transition={{ duration: 0.5 }}>{ExclusiveIndulgences[2].heading}</motion.p>
                                <div className='d-flex exclusive_div'>
                                    <motion.h4 variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}


                                        transition={{ duration: 0.5 }}>{ExclusiveIndulgences[2].title}</motion.h4>
                                    <motion.p className='' variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}


                                        transition={{ duration: 0.5 }}>{ExclusiveIndulgences[2].content.substring(0, 110)}...</motion.p>



                                    {ExclusiveIndulgences[2]?.category?.filter(data => data.id == tabsData[0]?.thirdTab).map((two) => {
                                        return (

                                            <>

                                                <div className='right_img-d web-d'

                                                >
                                                    <span className='slide--current'
                                                        onMouseMove={handleMouseMove}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <div className="slide__image-wrapper">
                                                            <img className='right_img' variant="top" src={two.image1}

                                                            />
                                                        </div>
                                                    </span>


                                                </div>
                                            </>
                                        )
                                    })}


                                </div>
                            </>

                        </div>
                        <div className='section_maintwo'>
                            <div className='d-flex justify-content-between image-section' style={{ gap: '100px' }}>
                                {ExclusiveIndulgences[2]?.category?.filter(data => data.id == tabsData[0]?.thirdTab).map((two) => {
                                    return (

                                        <>

                                            <div className='web-d'>

                                                <span className='slide--current'
                                                    onMouseMove={handleMouseMove}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <div className="slide__image-wrapper">
                                                        <img className='left_img' variant="top" src={two.image2} style={{}} />
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    )
                                })}

                                <div className="links-list">
                                    {ExclusiveIndulgences[2]?.category?.map((two) => {
                                        return (

                                            <>
                                                <div className='tab_accordion__'>
                                                    <Link onClick={() => handleTabs(ExclusiveIndulgences[2]?.id, two.id)}
                                                        className={tabsData[0]?.thirdTab == two.id ? "h3 links-list-active active-cursor active" : "h3 links-list-active active-cursor"}>
                                                        <div className='d-flex gap-2 hide_show_hover'>
                                                            <motion.h6 variants={textVariants('left', 0.2)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }}


                                                                transition={{ duration: 0.5 }}>{two.subtitle}</motion.h6>
                                                            <div className='right_arrow_svg'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="2.4848958333333333vw" height="3.0421875vw" viewBox="0 0 58.652 49.111">
                                                                    <g id="Group_630" data-name="Group 630" transform="translate(58.41 0.645) rotate(90)">
                                                                        <path id="Path_922" data-name="Path 922" d="M0,23.9S21.176,13.818,23.438,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="3" />
                                                                        <path id="Path_923" data-name="Path 923" d="M24.084,23.807S.094,9.692,0,0" transform="translate(23.622 0.092)" fill="none" stroke="#fff" stroke-width="3" />
                                                                        <path id="Path_924" data-name="Path 924" d="M0,58.226V0" transform="translate(23.626 0.185)" fill="none" stroke="#fff" stroke-width="3" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className='tab_accordion_'>
                                                    <Accordion expanded={expanded[0]?.thirdTab === `panel${ExclusiveIndulgences[2]?.id}${two.id}`}

                                                    >
                                                        <AccordionSummary

                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                        >
                                                            <Link onClick={() => handleTabs(ExclusiveIndulgences[2]?.id, two.id)}
                                                                className={tabsData[0]?.thirdTab == two.id ? "h3 links-list-active active-cursor active" : "h3 links-list-active active-cursor"}>
                                                                <div className='d-flex gap-2 hide_show_hover'>
                                                                    <motion.h6 variants={textVariants('left', 0.2)}
                                                                        initial="hidden"
                                                                        whileInView="show"
                                                                        viewport={{ once: true, amount: 0.1 }}


                                                                        transition={{ duration: 0.5 }}>{two.subtitle}</motion.h6>
                                                                    <div className='right_arrow_svg'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="2.4848958333333333vw" height="3.0421875vw" viewBox="0 0 58.652 49.111">
                                                                            <g id="Group_630" data-name="Group 630" transform="translate(58.41 0.645) rotate(90)">
                                                                                <path id="Path_922" data-name="Path 922" d="M0,23.9S21.176,13.818,23.438,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                <path id="Path_923" data-name="Path 923" d="M24.084,23.807S.094,9.692,0,0" transform="translate(23.622 0.092)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                <path id="Path_924" data-name="Path 924" d="M0,58.226V0" transform="translate(23.626 0.185)" fill="none" stroke="#fff" stroke-width="3" />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </AccordionSummary>
                                                        <AccordionDetails className='p-0'>
                                                            <TabSlider sliderData={ExclusiveIndulgences[2]?.category[two.id - 1]} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>

                                            </>
                                        )
                                    })}
                                </div >
                            </div>
                        </div>

                    </TabPanel>

                    <TabPanel value="4">
                        <div className='section_mainone'>

                            <>
                                <motion.p variants={textVariants('left', 0.2)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}


                                    transition={{ duration: 0.5 }}>{ExclusiveIndulgences[3].heading}</motion.p>
                                <div className='d-flex exclusive_div'>
                                    <motion.h4 variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}


                                        transition={{ duration: 0.5 }}>{ExclusiveIndulgences[3].title}</motion.h4>
                                    <motion.p className='' variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}


                                        transition={{ duration: 0.5 }}>{ExclusiveIndulgences[3].content.substring(0, 110)}...</motion.p>



                                    {ExclusiveIndulgences[3]?.category?.filter(data => data.id == tabsData[0]?.fourthTab).map((two) => {
                                        return (

                                            <>
                                                <div className='right_img-d web-d'

                                                >
                                                    <span className='slide--current'
                                                        onMouseMove={handleMouseMove}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <div className="slide__image-wrapper">
                                                            <img className='right_img' variant="top" src={two.image1}

                                                            />
                                                        </div>
                                                    </span>


                                                </div>
                                            </>
                                        )
                                    })}


                                </div>
                            </>


                        </div>
                        <div className='section_maintwo'>
                            <div className='d-flex justify-content-between image-section' style={{ gap: '100px' }}>
                                {ExclusiveIndulgences[3]?.category?.filter(data => data.id == tabsData[0]?.fourthTab).map((two) => {
                                    return (
                                        <>
                                            <div className='web-d'>
                                                <span className='slide--current'
                                                    onMouseMove={handleMouseMove}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <div className="slide__image-wrapper">
                                                        <img className='left_img' variant="top" src={two.image2} style={{}} />
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    )
                                })}

                                <div className="links-list">
                                    {ExclusiveIndulgences[3]?.category?.map((two) => {
                                        return (

                                            <>
                                                <div className='tab_accordion__'>
                                                    <Link onClick={() => handleTabs(ExclusiveIndulgences[3]?.id, two.id)}
                                                        className={tabsData[0]?.fourthTab == two.id ? "h3 links-list-active active-cursor active" : "h3 links-list-active active-cursor"}>
                                                        <div className='d-flex gap-2 hide_show_hover'>
                                                            <motion.h6 variants={textVariants('left', 0.2)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }}


                                                                transition={{ duration: 0.5 }}>{two.subtitle}</motion.h6>
                                                            <div className='right_arrow_svg'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="2.4848958333333333vw" height="3.0421875vw" viewBox="0 0 58.652 49.111">
                                                                    <g id="Group_630" data-name="Group 630" transform="translate(58.41 0.645) rotate(90)">
                                                                        <path id="Path_922" data-name="Path 922" d="M0,23.9S21.176,13.818,23.438,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="3" />
                                                                        <path id="Path_923" data-name="Path 923" d="M24.084,23.807S.094,9.692,0,0" transform="translate(23.622 0.092)" fill="none" stroke="#fff" stroke-width="3" />
                                                                        <path id="Path_924" data-name="Path 924" d="M0,58.226V0" transform="translate(23.626 0.185)" fill="none" stroke="#fff" stroke-width="3" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className='tab_accordion_'>
                                                    <Accordion expanded={expanded[0]?.fourthTab === `panel${ExclusiveIndulgences[3]?.id}${two.id}`}

                                                    >
                                                        <AccordionSummary

                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                        >
                                                            <Link onClick={() => handleTabs(ExclusiveIndulgences[3]?.id, two.id)}
                                                                className={tabsData[0]?.fourthTab == two.id ? "h3 links-list-active active-cursor active" : "h3 links-list-active active-cursor"}>
                                                                <div className='d-flex gap-2 hide_show_hover'>
                                                                    <motion.h6 variants={textVariants('left', 0.2)}
                                                                        initial="hidden"
                                                                        whileInView="show"
                                                                        viewport={{ once: true, amount: 0.1 }}


                                                                        transition={{ duration: 0.5 }}>{two.subtitle}</motion.h6>
                                                                    <div className='right_arrow_svg'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="2.4848958333333333vw" height="3.0421875vw" viewBox="0 0 58.652 49.111">
                                                                            <g id="Group_630" data-name="Group 630" transform="translate(58.41 0.645) rotate(90)">
                                                                                <path id="Path_922" data-name="Path 922" d="M0,23.9S21.176,13.818,23.438,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                <path id="Path_923" data-name="Path 923" d="M24.084,23.807S.094,9.692,0,0" transform="translate(23.622 0.092)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                <path id="Path_924" data-name="Path 924" d="M0,58.226V0" transform="translate(23.626 0.185)" fill="none" stroke="#fff" stroke-width="3" />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </AccordionSummary>
                                                        <AccordionDetails className='p-0'>
                                                            <TabSlider sliderData={ExclusiveIndulgences[3]?.category[two.id - 1]} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>

                                            </>
                                        )
                                    })}
                                </div >
                            </div>
                        </div>

                    </TabPanel>
                </TabContext>
            </Box>

        </div >
    );
}

