import React from 'react'
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { svgVariants, textVariants, pathVariants } from '../component/motion';
import { motion } from 'framer-motion';
import CTASvg from './CTASvg';

const Error = () => {
    return (
        <>
            <section className='main_section '>
                <div className='main_bg blankpageBg'>
                    <motion.div className='main_heading err-d' variants={textVariants('up', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <h1>404</h1>
                        <p className='pagenottext'>The page you were looking for does not exist</p>
                        <Link to="/" className='blankpagebutton'>
                            <div className="action active-cursor decor no-transition" style={{ translate: "none", rotate: "none", scale: " none", transform: "translate(0px, 0px)", opacity: '1' }}>
                                <p className='m-0'>Back to home</p>
                               <CTASvg />
                            </div>
                        </Link>
                    </motion.div>
                </div>
            </section>
        </>
    )
}

export default Error