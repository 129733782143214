import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { textVariants, pathVariants } from "../component/motion";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CTASvg from "../common/CTASvg";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import config from "../config/config";
import Joi from "joi";
import http from "../helpers/http";
import { Link, useNavigate, useParams } from "react-router-dom";

const WriteAReview = ({ expanded,villa }) => {
  const [rating, setRating] = useState("");

  const [values, setValues] = useState({
    name: "",
    email: "",
    title: "",
    body: "",
    rating: 5,
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "/reviews";
  let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("First Name"),
   
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    // rating: Joi.number().required().min(0).label("Rating"),

   
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    console.log({errors})
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, {...values,project_id:villa._id,project_name:villa.title});

    if (data) {
      // redirect
      // navigate("/thank-you");
      setValues({
        name: "",
        email: "",
        title: "",
        body: "",
        rating: "",
      });

      // setModalShow(false);
      navigate("/thankyou");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  return (
    <>
      <Accordion expanded={expanded} className="review-accordion">
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        ></AccordionSummary>
        <AccordionDetails>
          <motion.Form
            className="contact_form"
            onSubmit={handleSubmit}
            autoComplete="off"
            variants={textVariants("top", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <Form.Group className="form-group_ ">
              <Form.Label>NAME*</Form.Label>
              <TextField
                variant="standard"
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <span className="err_msg">{formError.name}</span>
            </Form.Group>
            <Form.Group className="form-group_ ">
              <Form.Label>E-MAIL*</Form.Label>
              <TextField
                type="text"
                variant="standard"
                placeholder="Your e-mail"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              <span className="err_msg">{formError.email}</span>
            </Form.Group>
            <Form.Group className="w-100">
              <Form.Label>REVIEW TITLE</Form.Label>
              <TextField
                variant="standard"
                placeholder="Enter your message here"
                name="title"
                value={values.title}
                onChange={handleChange}
              />
              <span className="err_msg">{formError.title}</span>
            </Form.Group>
            <Form.Group className="w-100">
              <Form.Label>BODY OF REVIEW</Form.Label>
              <TextField
                variant="standard"
                placeholder="Enter your message here"
                name="body"
                value={values.body}
                onChange={handleChange}
              />
              <span className="err_msg">{formError.body}</span>
            </Form.Group>

            <Form.Group className="w-100">
              <Form.Label>RATING</Form.Label>
              <br></br>
              <Rating
                value={values.rating}
                name="rating"
                precision={0.5}
                onChange={handleChange}
                // emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              />
            </Form.Group>
            <span className="err_msg">{formError.rating}</span>

            <button className="contact_form_submit_button" type="submit">
              <div
                className="action active-cursor decor no-transition"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: " none",
                  transform: "translate(0px, 0px)",
                  opacity: "1",
                }}
              >
                <p className="m-0">Send Request</p>

                <CTASvg />
              </div>
            </button>
          </motion.Form>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default WriteAReview;
