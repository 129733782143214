export const menuItem = [
    {
        title: "Overview",
        url: "/overview"
    },
    {
        title: "Villas",
        url: "/villas"
    },
    {
        title: "Amenities",
        url: "/amenities"
    },
    {
        title: "Location",
        url: "/location"
    },
    {
        title: "Contact",
        url: "/contact"
    },
    // {
    //     title: "About",
    //     url: "/about"
    // },



]