import React from 'react';
import ctaBtn from '../assets/ctaBtn.png';
import ctaBtnFill from '../assets/ctaBtnFill.png';


const CTASvg = () => {
    return (
        <>
            <div className="circle-desc">
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="3.8731770833333328vw" height="3.4944791666666664vw" viewBox="0 0 74.365 67.094">
                    <g id="button_strock_2_" data-name="button (strock 2)" transform="translate(-255 -969.148)">
                        <line id="Line_1" data-name="Line 1" x2="24.61" transform="translate(304.755 1002.695)" fill="none" stroke="#fff" stroke-width="2" />
                    </g>
                </svg>
                <img className='ctaBtn_' src={ctaBtn} />
                <img className='ctaBtn_f' src={ctaBtnFill} />
            </div>
        </>
    )
}

export default CTASvg