import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import AnimatedCursor from 'react-animated-cursor';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Wp from '../web/assets/whatsappIcon.png';


const Frontend = () => {


const navigate = useNavigate();
  const [isShowCursor, setIsShowCursor] = useState(true);


  useEffect(() => {
    setIsShowCursor(isShowCursor);
  }, [isShowCursor]);

  return (
    <>
      <Header />

      <Outlet context={[setIsShowCursor]} />


      <div className='wp_d'>
        <a
          href="https://wa.me/919322983811"
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Wp} />
        </a>
      </div>


      {/* <Paper className='book_now_btn_d' sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          className='book_now_btn_'
        >
          <BottomNavigationAction label="Book Now" onClick={() => navigate('/contact')} />

        </BottomNavigation>
      </Paper> */}

      <div className='cursor_'>
        <AnimatedCursor
          innerSize={8}
          outerSize={32}
          innerScale={1}
          outerScale={1}
          outerAlpha={0}
          outerStyle={{
            border: '1px solid white',
            display: isShowCursor ? 'block' : 'none',
            zIndex: '9999'
          }}
          innerStyle={{
            backgroundColor: 'white',
            display: isShowCursor ? 'block' : 'none',
            zIndex: '9999'
          }}

        />
      </div>

      <Footer />
    </>
  )
}

export default Frontend