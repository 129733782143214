import axios from "axios";

import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";

import config from "../config/config";
import moment from "moment";

const helper = {
  validateEmail: async (email, modelName) => {
    try {
      const { data: res } = await axios.post(
        `${config.api_url}general/email-validation`,
        {
          email,
          modelName,
        }
      );

      if (res.status !== 200) {
        return false;
      }
      return true;
    } catch (error) {
      toast.error("Internal Server Error");
      console.log("error", error.response);
      //   return false;
    }
  },

  // getUser: () => {
  //   const token = localStorage.getItem(config.jwt_auth_key);
  //   if (token) return jwtDecode(token);
  // },

  checkWordLen: (text, length) => {
    var len = text.split(/[\s]+/);
    console.log("word length", len.length);
    if (len.length > length) {
      return false;
    }
    return true;
  },

    ISTDate: (date, format = "YYYY-MM-DD HH:mm:ss") => {
      return moment(date).utc().utcOffset("+05:30").format(format);
    },
};
export default helper;

export const isInViewPort = (element, eleStr) => {
  // Get the bounding client rectangle position in the viewport
  var bounding = element.getBoundingClientRect();
  // Checking part. Here the code checks if it's *fully* visible
  // Edit this part if you just want a partial visibility
  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
  ) {
    const el = document.querySelectorAll(`${eleStr} .react-player`);
    const letterArr = Array.from(el);
    const animName = "letterAnim";
    let animDelay = 0;

    letterArr.forEach((spanEl) => {
      spanEl.style.animation = `${animName} 1s linear ${animDelay}s forwards`;
      animDelay += 0.1;
    });
  }
};

export function handelRightClick(event) {
  event.preventDefault();
}
