export const navVariants = {
    hidden: {
        opacity: 0,
        y: -50,
        transition: {
            type: 'spring',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            stiffness: 100,
        },
    }
}



export const fixedContainer = (content, delay) => ({
    hidden: {},
    show: {
        transition: {
            content,
            delay,
        },
    }
})

export const textVariants = (direction, delay) => ({
    hidden: {
        x: direction === 'left' ? -50 : direction === 'right' ? 50 : 0,
        y: direction === 'up' ? 50 : direction === 'down' ? -50 : 0,
        opacity: 0,
    },
    show: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 1.25,
            delay
        },
    }
})



export const sliderVariants = (x) => ({
    hidden: {
        x: x,
        opacity: 0,
    },
    show: {
        x: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 1.25,

        },
    }
})


export const formVariants = (delay) => ({
    hidden: {
        x: -50,
        opacity: 0,
    },
    show: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.25,
            delay,
        },
    }
})

export const fadeIn = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.75,
        }
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.75,
        }
    }
}


export const textFadeIn = (delay, opacity) => ({
    hidden: {
        visibility: 'hidden',
        opacity: 0,
        transition: {
            duration: 2,
            delay: delay
        }
    },
    visible: {
        visibility: 'visible',
        opacity: opacity,
        transition: {
            duration: 2,
            delay: delay
        }
    }
})



export const fixedBtn = {
    hidden: {
        x: 24,
        y: -140,
        opacity: 0,
        transition: {
            duration: 0.50,
        }
    },
    show: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.50,
        }
    },
}

// export const svgVariants = {
//     left: {
//         x: 0,
//         transition: {
//             duration: 0.3
//         }
//     },
//     right: {
//         x: -250,
//         transition: {
//             duration: 0.3
//         }
//     }
// }

export const svgVariants = (x, y) => ({
    hidden: {
        x: x,
        y: y,
        transition: {
            duration: 0.3
        }
    },
    visible: {
        x: x,
        y: y,
        transition: {
            duration: 0.3
        }
    }
})

export const pathVariants = (d) => ({
    hidden: {
        opacity: 0,
        pathLength: 0
    },
    visible: {
        opacity: 1,
        pathLength: 1,
        transition: {
            duration: d,
            ease: "easeInOut"
        }
    }
})

export const pathCircleVariants = (delay) => ({
    hidden: {
        opacity: 0,
        pathLength: 0
    },
    show: {
        opacity: 1,
        pathLength: 1,
        transition: {
            ease: "easeInOut",
            duration: 1.25,
            delay
        },
    }
})