import _ from "lodash";


import Image1 from './web/assets/slider1.jpg';
import Image2 from './web/assets/slider2.jpg';
import Image3 from './web/assets/slider3.jpg';
import Image4 from './web/assets/slider4.jpg';
import HomeBanner1 from './web/assets/HomeBanner1.jpg';
import HomeBanner2 from './web/assets/HomeBanner2.jpg';
import HomeBanner3 from './web/assets/HomeBanner3.jpg';
import HomeBanner4 from './web/assets/HomeBanner4.jpg';

// villa image
import villa1 from './web/assets/villaSlider3.jpg';
import villa2 from './web/assets/villa2.jpg';
import villa3 from './web/assets/villa3.jpg';
import villa4 from './web/assets/villa4.jpg';



// villas Slider
import VillaSlider1 from './web/assets/Home/Villa section/ArlingtonMeadows1.jpg'
import VillaSlider2 from './web/assets/Home/Villa section/ArlingtonClassic.jpg'
import VillaSlider3 from './web/assets/Home/Villa section/ArlingtonMeadows2.jpg'
import VillaSlider4 from './web/assets/Home/Villa section/ArlingtonContemporary.jpg'

// gallery
import gallerySlider1 from './web/assets/Home/Gallery section/gallery 1.png'
import gallerySlider2 from './web/assets/Home/Gallery section/gallery 2.png'
import gallerySlider3 from './web/assets/Home/Gallery section/gallery 3.png'
import gallerySlider4 from './web/assets/Home/Gallery section/gallery 4.png'
import gallerySlider5 from './web/assets/Home/Gallery section/gallery 5.png'
import gallerySlider6 from './web/assets/Home/Gallery section/gallery 6.png'
import gallerySlider7 from './web/assets/Home/Gallery section/gallery 7.png'
import gallerySlider8 from './web/assets/Home/Gallery section/gallery 8.png'

// amenities section
import AmenitiesSection1Img1 from './web/assets/Home/Amenitiessection/Arlington Classic 6BHK (1).jpg'
import AmenitiesSection1Img2 from './web/assets/Home/Amenitiessection/Arlington Classic 6BHK (2) lower.jpg'
import AmenitiesSection2Img1 from './web/assets/Home/Amenitiessection/Arlington Meadows 1 3BHK (1).jpg'
import AmenitiesSection2Img2 from './web/assets/Home/Amenitiessection/Arlington Meadows 1 3BHK (2) lower.jpg'
import AmenitiesSection3Img1 from './web/assets/Home/Amenitiessection/Arlington Meadows 2 4BHK (1).jpg'
import AmenitiesSection3Img2 from './web/assets/Home/Amenitiessection/Arlington Meadows 2 4BHK (2) lower.jpg'
import AmenitiesSection4Img1 from './web/assets/Home/Amenitiessection/Arlington Contemporary 5BHK (1).jpg'
import AmenitiesSection4Img2 from './web/assets/Home/Amenitiessection/Arlington Contemporary 5BHK (2) lower.jpg'
// import AmenitiesSection4Img1 from './web/assets/Home/Amenitiessection/Arlington Classic 6BHK (1).jpg'
// import AmenitiesSection4Img2 from './web/assets/Home/Amenitiessection/Arlington Classic 6BHK (2) lower.jpg'
// import gallerySlider5 from './web/assets/Home/Villa section/Luxury Villas section (5).jpg'

import author from './web/assets/author.jpg';


import Slider2 from './web/assets/image45.png';
import Slider1 from './web/assets/villaSlider2.jpg';
import Slider3 from './web/assets/villaSlider3.jpg';
import CollectionSlider2 from './web/assets/Arlington (freelance dev)/resources/538e7dae1b6290d1b460c1eb345876a1';
import CollectionSlider1 from './web/assets/Arlington (freelance dev)/resources/46ab9c012203f08613142d78f399f12e';
import CollectionSlider3 from './web/assets/CJB_9340.jpg';
import Card1 from './web/assets/Arlington (freelance dev)/resources/59dbe679b0caaf95c65a942c9a3eb293';
import Card2 from './web/assets/villa33.jpg';
import team from './web/assets/team.jpg';

// 
import heroImg from './web/assets/heroImg.jpg';
import RightImg from './web/assets/rightImg.jpg';


import CGP_IMG from './web/assets/CJB_9340.jpg';
import SliderImg from './web/assets/IMG_8532.jpg';
import SliderImg2 from './web/assets/IMG_20200907_163527_Original.jpg';

import tabImg from './web/assets/villa3.jpg';



// amenities tab data
import amenitiesImage1 from './web/assets/Amenities/Arlington Classic 6BHK.jpg'
import amenitiesImage2 from './web/assets/Amenities/Arlington Contemporary 5BHK.jpg'
import amenitiesImage3 from './web/assets/Amenities/Arlington Meadows 1 3BHK.jpg'
import amenitiesImage4 from './web/assets/Amenities/Arlington Meadows 2 4BHK.jpg'


import relaxing from './web/assets/villa33.jpg';
import bbq from './web/assets/bbq.jpg';
import indoorGames from './web/assets/indoorGames.jpg'

// location data

import mumbai from './web/assets/mumbai.jpg';
import pune from './web/assets/pune.jpg';
import highway from './web/assets/highway.jpg';
import aeroplan from './web/assets/aeroplan.jpg';
import train from './web/assets/train.jpg';
import hightway1 from './web/assets/highway1.jpg';


import rainFall from './web/assets/rainFall.jpg';
import winter from './web/assets/winter.jpg';
import summer from './web/assets/summer.jpg';

// villa details
import villaBanner1 from './web/assets/Villadeatil/Banners/ArlingtonClassic6BHK.jpg'
import villaBanner2 from './web/assets/Villadeatil/Banners/ArlingtonMeadows3BHK.jpg'
import villaBanner3 from './web/assets/Villadeatil/Banners/ArlingtonMeadows4BHK.jpg'
import villaBanner4 from './web/assets/Villadeatil/Banners/ArlingtonContemporary5BHK.jpg'


// single villa
import singleVilla1 from './web/assets/Villadeatil/Center image/Arlington Classic 6BHK.jpg';
import singleVilla2 from './web/assets/Villadeatil/Center image/Arlington Meadows 1 3BHK.jpg'
import singleVilla3 from './web/assets/Villadeatil/Center image/Arlington Meadows 2 4BHK.jpg'
import singleVilla4 from './web/assets/Villadeatil/Center image/Arlington Contemporary 5BHK.jpg'


// singleVilla amenities 
import wifi from './web/assets/wifi.jpg';
import parking from './web/assets/parking.jpg';
import living from './web/assets/living.jpg';
import bedroom from './web/assets/bedroom.jpg';
import swimming from './web/assets/swimming.jpg';
import tv from './web/assets/tv.jpg';
import ac from './web/assets/ac.jpg';
import terrace from './web/assets/terrace.jpg';
import garden from './web/assets/garden.jpg'


import Avatar1 from './web/assets/team.jpg';

// villa details slider 1 images
import villaSlider1 from './web/assets/Villadeatil/Gallery/Arlington Classic 6BHK/IMG_3700_1_2.jpg'
import villaSlider2 from './web/assets/Villadeatil/Gallery/Arlington Classic 6BHK/IMG_3541_2_3.jpg'
import villaSlider3 from './web/assets/Villadeatil/Gallery/Arlington Classic 6BHK/IMG_3664_5_6.jpg'
import villaSlider4 from './web/assets/Villadeatil/Gallery/Arlington Classic 6BHK/IMG_3619_20_21.jpg'
import villaSlider5 from './web/assets/Villadeatil/Gallery/Arlington Classic 6BHK/IMG_3538_39_40.jpg'


// villa details slider 2 images
import villaSlider6 from './web/assets/Villadeatil/Gallery/Arlington Meadows 1 3BHK/IMG_3481_2_3.jpg'
import villaSlider7 from './web/assets/Villadeatil/Gallery/Arlington Meadows 1 3BHK/IMG_3283_4_5.jpg'
import villaSlider8 from './web/assets/Villadeatil/Gallery/Arlington Meadows 1 3BHK/IMG_3364_5_6.jpg'
import villaSlider9 from './web/assets/Villadeatil/Gallery/Arlington Meadows 1 3BHK/IMG_3406_7_8.jpg'
import villaSlider10 from './web/assets/Villadeatil/Gallery/Arlington Meadows 1 3BHK/IMG_3829_30_31.jpg'

// villa details slider 2 images
import villaSlider11 from './web/assets/Villadeatil/Gallery/Arlington Meadows 2 4BHK/IMG_3040_1_2.jpg'
import villaSlider12 from './web/assets/Villadeatil/Gallery/Arlington Meadows 2 4BHK/IMG_3034_5_6.jpg'
import villaSlider13 from './web/assets/Villadeatil/Gallery/Arlington Meadows 2 4BHK/IMG_3076_7_8.jpg'
import villaSlider14 from './web/assets/Villadeatil/Gallery/Arlington Meadows 2 4BHK/IMG_3127_8_9.jpg'
import villaSlider15 from './web/assets/Villadeatil/Gallery/Arlington Meadows 2 4BHK/IMG_3157_8_9.jpg'

// villa details slider 2 images
import villaSlider16 from './web/assets/Villadeatil/Gallery/Arlington Contemporary 5BHK/IMG_3931_2_3.jpg'
import villaSlider17 from './web/assets/Villadeatil/Gallery/Arlington Contemporary 5BHK/IMG_3862_3_4.jpg'
import villaSlider18 from './web/assets/Villadeatil/Gallery/Arlington Contemporary 5BHK/IMG_4054_5_6.jpg'
import villaSlider19 from './web/assets/Villadeatil/Gallery/Arlington Contemporary 5BHK/IMG_4066_7_8.jpg'
import villaSlider20 from './web/assets/Villadeatil/Gallery/Arlington Contemporary 5BHK/IMG_4096_7_8.jpg'
import villaSlider21 from './web/assets/Villadeatil/Gallery/Arlington Contemporary 5BHK/IMG_4099_100_101.jpg'


export const DataTable = [
    {
        'content': "A UNIQUE LUXURY IN THE HEART OF THE WESTERN GHATS - PANCHGANI",
        'image': HomeBanner1,
    },
    {
        'content': "A UNIQUE LUXURY IN THE HEART OF THE WESTERN GHATS - PANCHGANI",
        'image': HomeBanner2,
    },
    {
        'content': "A UNIQUE LUXURY IN THE HEART OF THE WESTERN GHATS - PANCHGANI",
        'image': HomeBanner3,
    },
    {
        'content': "A UNIQUE LUXURY IN THE HEART OF THE WESTERN GHATS - PANCHGANI",
        'image': HomeBanner4,
    },
]
export const CardSection = [

    {
        'image': RightImg,
    },
    {
        'image': heroImg,
    }

]
export const aboutSection = [
    {

        'content': "<p>Arlington is your next home for that exotic stay </br>Arlington is a classic example of the amalgamation of art and luxury. Located amidst the plush mountains of Panchgani valley and alluring views of Dhom dam, Arlington villas are exquisitely designed for every necessity.Arlington offers unrivalled luxury stay escorted by 6BHK Arlington Classic Villa, 5BHK Arlington Contemporary Villa,</p>"

    },


]
export const gallerySection = [
    {

        'content': "Arlington offers deluxe experience which you would not want to rush over. Check out our gallery to discover the goodies of life specially designed by us for your elevated comfort."

    },


]
export const enquirySection = [
    {

        'image': "Arlington offers deluxe experience which you would not want to rush over. Check out our gallery to discover the goodies of life specially designed by us for your elevated comfort."

    },


]
export const contactPage =
{
    'address': "Panchgani, Maharashtra 412805",
    'email': 'contact@arlington.com',
    'phone': '+91 093229 83811',
    'url': 'https://goo.gl/maps/4nzEJPMym8J7gSrHA'
}

export const sliderVillas = [
    {
        id: 1,
        'image': VillaSlider1,
        'heading': 'DISCOVER MORE',
        'subheading': 'ARLINGTON MEADOWS I'
    },
    {
        id: 2,
        'image': VillaSlider2,
        'heading': 'DISCOVER MORE',
        'subheading': 'ARLINGTON CLASSIC'
    },
    {
        id: 3,
        'image': VillaSlider3,
        'heading': 'DISCOVER MORE',
        'subheading': 'ARLINGTON MEADOWS II'
    },
    {
        id: 4,
        'image': VillaSlider4,
        'heading': 'DISCOVER MORE',
        'subheading': 'ARLINGTON CONTEMPORARY'
    },

]

export const overviewSlider = [
    {
        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {

        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {

        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {
        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {

        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {

        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {
        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {

        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {

        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {
        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {

        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
    {

        'image': team,
        'heading': "KEITH D'SOUZA",
        'subheading': 'Business Development Manager'
    },
]


export const sliderCollection = [
    {
        image: gallerySlider1,
    },
    {
        image: gallerySlider2,
    },
    {
        image: gallerySlider3,
    },
    {
        image: gallerySlider4,
    },
    {
        image: gallerySlider5,
    },
    {
        image: gallerySlider6,
    },
    {
        image: gallerySlider7,
    },
    {
        image: gallerySlider8,
    }

]

export const tabOneSectionOne = [
    {

        'heading': 'Amenities',
        'subheading': 'EXCLUSIVE INDULGENCES',
        'content': 'Extravagance is never too much Our exceptional facilities feature elegant interiors, state-of-the-art amenities, and expertly trained staff, ensuring an indulgent and unforgettable stay.',
        'image': Card1,
    },
]
export const tabOneSectionTwo = [
    {
        'list1': 'RELAXING MASSAGE',
        'list2': 'INDOOR GAMES',
        'list3': 'LIVE BBQ',
        'image': Card2,
    },


]
export const tabTwoSectionOne = [
    {

        'image': CollectionSlider1,
    },
    {

        'image': CollectionSlider2,

    },
    {

        'image': CollectionSlider3,
    },


]
export const tabTwoSectionTwo = [
    {

        'image': CollectionSlider1,
    },
    {

        'image': CollectionSlider2,

    },
    {

        'image': CollectionSlider3,
    },


]
export const tabThreeSectionOne = [
    {

        'image': CollectionSlider1,
    },
    {

        'image': CollectionSlider2,

    },
    {

        'image': CollectionSlider3,
    },


]
export const tabThreeSectionTwo = [
    {

        'image': CollectionSlider1,
    },
    {

        'image': CollectionSlider2,

    },
    {

        'image': CollectionSlider3,
    },


]
export const tabFourSectionOne = [
    {

        'image': CollectionSlider1,
    },
    {

        'image': CollectionSlider2,

    },
    {

        'image': CollectionSlider3,
    },


]
export const tabFourSectionTwo = [
    {

        'image': CollectionSlider1,
    },
    {

        'image': CollectionSlider2,

    },
    {

        'image': CollectionSlider3,
    },


]



export const ExclusiveIndulgences =
    [
        {
            id: 1,
            heading: "Amenities",
            title: "EXCLUSIVE INDULGENCES",
            content: "Unleash luxury at Arlington Classic, our flagship 6 BHK Villa. Experience opulent living in this magnificent retreat with exquisite decor and cutting-edge amenities. With sprawling spaces, it's perfect for unwinding and creating cherished memories. Mesmerizing mountain views backdrop your extraordinary holiday. Discover unparalleled elegance at Arlington Classic.",
            category: [{
                id: 1,
                subtitle: "RELAXING MASSAGE",
                image1: AmenitiesSection1Img1,
                image2: AmenitiesSection1Img2,
            }, {
                id: 2,
                subtitle: "INDOOR GAMES",
                image1: AmenitiesSection1Img1,
                image2: AmenitiesSection1Img2,
            },
            {
                id: 3,
                subtitle: "Live BBQ",
                image1: AmenitiesSection1Img1,
                image2: AmenitiesSection1Img2,
            },
            ]
        },
        {
            id: 2,
            heading: "Amenities",
            title: "EXCLUSIVE INDULGENCES",
            content: "Discover serenity at Arlington Meadows I, our 3 BHK Villa. Embrace tranquility in this peaceful abode, nestled in the natural beauty of Panchgani. Designed for ultimate comfort and relaxation, it features modern amenities, cozy decor, and a private swimming pool. Perfect for a small family or group of friends, it offers an ideal escape from city life's chaos. Experience blissful retreat at Arlington Meadows I.",
            category: [{
                id: 1,
                subtitle: "RELAXING MASSAGE",
                image1: AmenitiesSection2Img1,
                image2: AmenitiesSection2Img2,
            }, {
                id: 2,
                subtitle: "INDOOR GAMES",
                image1: AmenitiesSection2Img1,
                image2: AmenitiesSection2Img2,
            },
            {
                id: 3,
                subtitle: "Live BBQ",
                image1: AmenitiesSection2Img1,
                image2: AmenitiesSection2Img2,
            },
            ]
        },
        {
            id: 3,
            heading: "Amenities",
            title: "EXCLUSIVE INDULGENCES",
            content: "Unwind in unparalleled comfort at our 4 BHK Villa, nestled amidst the scenic beauty of Panchgani. With stunning mountain views, this oasis offers a perfect retreat for medium-sized families or groups of friends. Indulge in state-of-the-art amenities and stylish decor, complemented by a private swimming pool. Discover a space that invites relaxation and rejuvenation like never before.",
            category: [{
                id: 1,
                subtitle: "RELAXING MASSAGE",
                image1: AmenitiesSection3Img1,
                image2: AmenitiesSection3Img2,
            }, {
                id: 2,
                subtitle: "INDOOR GAMES",
                image1: AmenitiesSection3Img1,
                image2: AmenitiesSection3Img2,
            },
            {
                id: 3,
                subtitle: "Live BBQ",
                image1: AmenitiesSection3Img1,
                image2: AmenitiesSection3Img2,
            },
            ]
        },
        {
            id: 4,
            heading: "Amenities",
            title: "EXCLUSIVE INDULGENCES",
            content: "Indulge in contemporary luxury at our exquisite 5 BHK Villa, nestled amidst nature's marvel. Immerse yourself in sleek decor and enjoy top-notch amenities for a truly lavish stay. The terrace offers awe-inspiring valley views, perfect for thrilling adventures. Whether it's a family gathering or a group getaway, our villa guarantees an unforgettable holiday. Unleash the allure of modern living at our exclusive 5 BHK Villa. Book now for an extraordinary escape!",
            category: [{
                id: 1,
                subtitle: "RELAXING MASSAGE",
                image1: AmenitiesSection4Img1,
                image2: AmenitiesSection4Img2,
            }, {
                id: 2,
                subtitle: "INDOOR GAMES",
                image1: AmenitiesSection4Img1,
                image2: AmenitiesSection4Img2,
            },
            {
                id: 3,
                subtitle: "Live BBQ",
                image1: AmenitiesSection4Img1,
                image2: AmenitiesSection4Img2,
            },
            ]
        }
    ]


export const tabData = {
    id: 1,
    heading: "Amenities",
    title: "EXCLUSIVE INDULGENCES",
    content: "Extravagance is never  oo much Our exceptional facilities feature elegant interiors, state-of-the-art amenities, and expertly trained staff, ensuring an indulgent and unforgettable stay.",
    category: [{
        id: 1,
        subtitle: "RELAXING MASSAGE",
        image1: CollectionSlider1,
        image2: CollectionSlider2,
    }, {
        id: 2,
        subtitle: "INDOOR GAMES",
        image1: CollectionSlider3,
        image2: CollectionSlider2,
    },
    {
        id: 3,
        subtitle: "LIVE BBQ",
        image1: CollectionSlider2,
        image2: CollectionSlider1,
    },
    ]
}




export const singleVillaSliderData = [
    {
        image: Image1,
    },
    {
        image: Image2,
    },
    {
        image: Image3,
    },
    {
        image: Image4,
    },

]


// overview data

export const overviewData = {
    title: 'ABOUT ARLINGTON',
    heading: '',
    content: 'Introducing Arlington Residencies, where luxury seamlessly intertwines with serenity. Located amidst the breathtaking landscapes of Panchgani, our resort showcases a collection of exquisite villas that redefine the essence of opulence. Currently, our offerings include the Arlington Classic, an elegant 6 BHK Villa, Arlington Meadows I, a charming 3 BHK Villa, Arlington Meadows II, a captivating 4 BHK Villa, and the Arlington Contemporary, an enchanting 5 BHK Villa while we continuously expand our portfolio.',
    founderName: 'STEVE JHON',
    about: "Each villa boasts a distinctive configuration, tailored to meet your discerning preferences. Immerse yourself in an unrivaled experience, as our contemporary facilities are meticulously designed to ensure your utmost comfort and enjoyment. Unwind with rejuvenating massages (available upon request, at actual cost), engage in thrilling indoor games, and revel in the seamless Wi-Fi connectivity. Although the Classic and Contemporary Villa do not feature a pool, our other accommodations proudly offer this refreshing amenity to maintain a tranquil ambiance, as we prioritize our guests' serenity. Indulge in an epitome of relaxation with our top-of-the-line sofas in the living room, plush memory foam mattresses, pillows, and branded towels and linen. Additionally, embrace the convenience of our fully equipped kitchen, complete with crockery and cutlery, allowing you to embark on culinary adventures at your leisure.",
    founderImage: author,

}

// location

export const locationData = {
    heading: "VILLAS DESIGNED TO RECONNECT WITH NATURE",
    content: 'True rejuvenation lies in reconnecting with the untamed beauty of nature. Nestled amidst breathtaking landscapes and enveloped by lush greenery, our sanctuary serves as a gateway to rediscover the harmony between man and the natural world. Step into our realm and feel the soothing embrace of the gentle breeze, listen to the melodic whispers of rustling leaves, and behold the mesmerizing dance of sunlight filtering through the foliage. Immerse yourself in the symphony of birdsong and the tranquil rhythm of flowing streams. Whether you embark on invigorating hikes, partake in thrilling outdoor adventures, or simply find solace in quiet contemplation, Arlington effortlessly intertwines you with the essence of nature.',
    gatewayData: {
        title: "GATEWAY TO FINE LIVING",
        description: "At Arlington, you will be well-connected to greenery as well as the commercial hubs of Mahabaleshwar city.",
        weather: {
            rainfall: {
                id: 1,
                name: "RAINFALL",
                image: rainFall,
                month: '(June - September)',
                temp: '24℃ - 30℃'
            },
            winter: {
                id: 2,
                name: "WINTER",
                image: winter,
                month: '(October - March)',
                temp: '8℃ - 22℃'
            },
            summer: {
                id: 3,
                name: "SUMMER",
                image: summer,
                month: '(April - May)',
                temp: '25℃ - 36℃'
            }
        }
    },
    data: {
        heading: 'CONNECTIVITY & PROXIMITY',
        description: 'Connectivity to greenery and proximity to luxury awaits you at Mahabaleshwar.',
        location: [
            {
                id: 1,
                name: 'MUMBAI INTERNATIONAL AIRPORT',
                distance: '5 hrs drive from Mangalyam Meadows',
                image: mumbai,
                icon: aeroplan
            },
            {
                id: 2,
                name: 'NATIONAL HIGHWAY NO. 48',
                distance: '3.7 kms away from Mahabaleshwar',
                image: highway,
                icon: hightway1
            },
            {
                id: 3,
                name: 'PUNE RAILWAY STATION',
                distance: '120 Mins drive from Pune Station',
                image: pune,
                icon: train
            }
        ]
    }

}



// amenities data
export const amenitiesData = [
    {
        id: 1,
        heading: 'Arlington Classic',
        title: 'THE PLACE WHERE TIME STOPS',
        content: "Arlington is your next home for that exotic stay Arlington is a classic example of the amalgamation of art and luxury.Located amidst the plush mountains of Panchgani valley and alluring views of Dhom dam, Arlington villas are exquisitely designed for every necessity.Arlington offers unrivalled luxury stay escorted by 6BHK Arlington Classic Villa, 5BHK Arlington Contemporary Villa, 3BHK Arlington Meadows 1, and 4BHK Arlington Meadows 2. With extravagant modern amenities, Arlington Villas are a dream recreational homes where one can relax, have a great time with family and friends and fall in love with the heavenly belle of the Panchgani hills.",
        image: amenitiesImage1,
        videoUrl: 'https://www.youtube-nocookie.com/embed/EngW7tLk6R8?controls=0',
        amenities: [
            {
                id: 1,
                title: 'RELAXING MASSAGE',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: relaxing

            },
            {
                id: 2,
                title: 'INDOOR GAMES',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: indoorGames

            },
            {
                id: 3,
                title: 'LIVE BBQ',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: bbq

            }
        ]

    },
    {
        id: 2,
        heading: 'Arlington Meadows - I',
        title: 'THE PLACE WHERE TIME STOPS',
        content: "Arlington is your next home for that exotic stay Arlington is a classic example of the amalgamation of art and luxury.Located amidst the plush mountains of Panchgani valley and alluring views of Dhom dam, Arlington villas are exquisitely designed for every necessity.Arlington offers unrivalled luxury stay escorted by 6BHK Arlington Classic Villa, 5BHK Arlington Contemporary Villa, 3BHK Arlington Meadows 1, and 4BHK Arlington Meadows 2. With extravagant modern amenities, Arlington Villas are a dream recreational homes where one can relax, have a great time with family and friends and fall in love with the heavenly belle of the Panchgani hills.",
        image: amenitiesImage2,
        videoUrl: 'https://www.youtube-nocookie.com/embed/EngW7tLk6R8?controls=0',
        amenities: [
            {
                id: 1,
                title: 'RELAXING MASSAGE',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: relaxing

            },
            {
                id: 2,
                title: 'INDOOR GAMES',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: indoorGames

            },
            {
                id: 3,
                title: 'LIVE BBQ',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: bbq

            }
        ]

    },
    {
        id: 3,
        heading: 'Arlington Meadows - II',
        title: 'THE PLACE WHERE TIME STOPS',
        content: "Arlington is your next home for that exotic stay Arlington is a classic example of the amalgamation of art and luxury.Located amidst the plush mountains of Panchgani valley and alluring views of Dhom dam, Arlington villas are exquisitely designed for every necessity.Arlington offers unrivalled luxury stay escorted by 6BHK Arlington Classic Villa, 5BHK Arlington Contemporary Villa, 3BHK Arlington Meadows 1, and 4BHK Arlington Meadows 2. With extravagant modern amenities, Arlington Villas are a dream recreational homes where one can relax, have a great time with family and friends and fall in love with the heavenly belle of the Panchgani hills.",
        image: amenitiesImage3,
        videoUrl: 'https://www.youtube-nocookie.com/embed/EngW7tLk6R8?controls=0',
        amenities: [
            {
                id: 1,
                title: 'RELAXING MASSAGE',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: relaxing

            },
            {
                id: 2,
                title: 'INDOOR GAMES',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: indoorGames

            },
            {
                id: 3,
                title: 'LIVE BBQ',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: bbq

            }
        ]

    },
    {
        id: 4,
        heading: 'Arlington Contemporary',
        title: 'THE PLACE WHERE TIME STOPS',
        content: "Arlington is your next home for that exotic stay Arlington is a classic example of the amalgamation of art and luxury.Located amidst the plush mountains of Panchgani valley and alluring views of Dhom dam, Arlington villas are exquisitely designed for every necessity.Arlington offers unrivalled luxury stay escorted by 6BHK Arlington Classic Villa, 5BHK Arlington Contemporary Villa, 3BHK Arlington Meadows 1, and 4BHK Arlington Meadows 2. With extravagant modern amenities, Arlington Villas are a dream recreational homes where one can relax, have a great time with family and friends and fall in love with the heavenly belle of the Panchgani hills.",
        image: amenitiesImage4,
        videoUrl: 'https://www.youtube-nocookie.com/embed/EngW7tLk6R8?controls=0',
        amenities: [
            {
                id: 1,
                title: 'RELAXING MASSAGE',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: relaxing

            },
            {
                id: 2,
                title: 'INDOOR GAMES',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: indoorGames

            },
            {
                id: 3,
                title: 'LIVE BBQ',
                content: 'Indulge in the epitome of relaxation with our exceptional range of relaxing massages. Our skilled therapists will expertly knead away your stress and tension, leaving you in a state of pure tranquility. From Swedish massages to aromatherapy sessions, each treatment is tailored to your specific needs, ensuring a personalized and blissful experience. Immerse yourself in the serene ambiance of our spa and let our dedicated professionals transport you to a world of unparalleled relaxation and rejuvenation.',
                image: bbq

            }
        ]

    }
]



// single villa content


export const villaData = [
    {
        _id:1,
        id: "arlington-classic",
        banner: villaBanner1,
        title: 'ARLINGTON CLASSIC',
        location: "Panchgani, Mahabaleshwar",
        content: "Our flagship 6 BHK Villa is a grand and opulent retreat, designed to provide guests with a luxurious living experience. The Villa features tasteful decor and state-of-the-art amenities that cater to the needs of a large family or group of friends. The spacious living areas and the stunning views of the surrounding mountains make for a perfect holiday experience.",
        image: villa1,
        centerImage: singleVilla1,
        plotSize: "~3,750 sq. m.",
        builtArea: "~8,041 sq. ft.",
        bedrooms: "5",
        price: '37,800',
        gst: '',
        locationUrl: 'https://maps.app.goo.gl/rstLRUqGRVV3bHTR9?g_st=iw',
        videoUrl: 'https://goo.gl/maps/WruuQoyJgKAmX3Rm7',
        facilities: [
            { name: "Wifi", image: wifi },
            { name: "Tv", image: tv },
            { name: "Air Conditioning", image: ac },
            { name: "Bedrooms", image: bedroom },
            { name: "Swimming Pool", image: swimming },
            { name: "Garden", image: garden },
            { name: "Parking Space", image: parking },
            { name: "Living Room", image: living },
            { name: "Terrace", image: terrace },
        ],
        villaSlider: [
            {
                id: 1,
                image: villaSlider1
            },
            {
                id: 2,
                image: villaSlider2
            },
            {
                id: 3,
                image: villaSlider3
            },
            {
                id: 4,
                image: villaSlider4
            },
            {
                id: 5,
                image: villaSlider5
            }
        ],
        reviews: [
            {
                id: 1,
                name: "Gaurav",
                date: "March 2023",
                image: { Avatar1 },
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },
            {
                id: 2,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },
            {
                id: 2,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },

            {
                id: 4,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            }

        ]
    },
    {
        _id:2,
        id: "arlington-meadows-i",
        banner: villaBanner2,
        title: 'ARLINGTON MEADOWS I',
        location: "Panchgani, Mahabaleshwar",
        content: "Our 3 BHK Villa is a serene and peaceful abode, offering guests a tranquil environment amidst the natural beauty of Panchgani. The Villa is designed to provide maximum comfort and relaxation, with modern amenities, cozy decor and a serene private swimming pool. Ideal for a small family or group of friends, the Villa offers a perfect getaway from the hustle and bustle of city life.",
        image: villa2,
        centerImage: singleVilla2,
        plotSize: "~3,750 sq. m.",
        builtArea: "~8,041 sq. ft.",
        bedrooms: "5",
        price: '32,800',
        gst: '',
        locationUrl: 'https://maps.app.goo.gl/Ee12L2FL1ynBjkSg7?g_st=iw',
        videoUrl: 'https://goo.gl/maps/JLdpYMZwrwAdSmxr6',
        facilities: [
            { name: "Wifi", image: wifi },
            { name: "Tv", image: tv },
            { name: "Air Conditioning", image: ac },
            { name: "Bedrooms", image: bedroom },
            { name: "Swimming Pool", image: swimming },
            { name: "Garden", image: garden },
            { name: "Parking Space", image: parking },
            { name: "Living Room", image: living },
            { name: "Terrace", image: terrace },
        ],
        villaSlider: [
            {
                id: 1,
                image: villaSlider6
            },
            {
                id: 2,
                image: villaSlider7
            },
            {
                id: 3,
                image: villaSlider8
            },
            {
                id: 4,
                image: villaSlider9
            },
            {
                id: 5,
                image: villaSlider10
            }
        ],
        reviews: [
            {
                id: 1,
                name: "Gaurav",
                date: "March 2023",
                image: { Avatar1 },
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },
            {
                id: 2,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },
            {
                id: 2,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },

            {
                id: 4,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            }

        ]
    },
    {
        _id:3,
        id: "arlington-meadows-ii",
        banner: villaBanner3,
        title: 'ARLINGTON MEADOWS II',
        location: "Panchgani, Mahabaleshwar",
        content: "Our 4 BHK Villa is an oasis of luxury and comfort, situated amidst the scenic beauty of Panchgani. The Villa offers a breathtaking view of the mountains and is designed to cater to the needs of a medium-sized family or group of friends. Equipped with state-of-the-art amenities and a tasteful decor with a private swimming pool, the Villa offers an ideal space for relaxation and rejuvenation.",
        image: villa3,
        centerImage: singleVilla3,
        plotSize: "~3,750 sq. m.",
        builtArea: "~8,041 sq. ft.",
        bedrooms: "5",
        price: '35,800',
        gst: '',
        locationUrl: 'https://maps.app.goo.gl/fQutLFzzPUvK2KfE9?g_st=iw',
        videoUrl: 'https://goo.gl/maps/RfKKgLKBf5PwxhP7A',
        facilities: [
            { name: "Wifi", image: wifi },
            { name: "Tv", image: tv },
            { name: "Air Conditioning", image: ac },
            { name: "Bedrooms", image: bedroom },
            { name: "Swimming Pool", image: swimming },
            { name: "Garden", image: garden },
            { name: "Parking Space", image: parking },
            { name: "Living Room", image: living },
            { name: "Terrace", image: terrace },
        ],
        villaSlider: [
            {
                id: 1,
                image: villaSlider11
            },
            {
                id: 2,
                image: villaSlider12
            },
            {
                id: 3,
                image: villaSlider13
            },
            {
                id: 4,
                image: villaSlider14
            },
            {
                id: 5,
                image: villaSlider15
            }
        ],
        reviews: [
            {
                id: 1,
                name: "Gaurav",
                date: "March 2023",
                image: { Avatar1 },
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },
            {
                id: 2,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },
            {
                id: 2,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },

            {
                id: 4,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            }

        ]
    },
    {
        _id:4,
        id: "arlington-contemporary",
        banner: villaBanner4,
        title: 'ARLINGTON CONTEMPORARY',
        location: "Panchgani, Mahabaleshwar",
        content: "Our 5 BHK Villa is a contemporary marvel, offering guests a luxurious and modern living experience in the lap of nature. The Villa features sleek and modern decor, with state-of-the-art amenities that cater to the needs of a large family or group of friends. A beautiful terrace with natural surroundings provides the fantastic view of the valley for a perfect backdrop for adventure activities, making it an ideal location for a fun-filled holiday.",
        image: villa4,
        centerImage: singleVilla4,
        plotSize: "~3,750 sq. m.",
        builtArea: "~8,041 sq. ft.",
        bedrooms: "5",
        price: '32,800',
        gst: '',
        locationUrl: 'https://maps.app.goo.gl/G3Tp2CtRCMC13LFg9?g_st=iw',
        videoUrl: 'https://goo.gl/maps/NbDUTYHE7yhWTg8t5',
        facilities: [
            { name: "Wifi", image: wifi },
            { name: "Tv", image: tv },
            { name: "Air Conditioning", image: ac },
            { name: "Bedrooms", image: bedroom },
            { name: "Swimming Pool", image: swimming },
            { name: "Garden", image: garden },
            { name: "Parking Space", image: parking },
            { name: "Living Room", image: living },
            { name: "Terrace", image: terrace },
        ],
        villaSlider: [
            {
                id: 1,
                image: villaSlider16
            },
            {
                id: 2,
                image: villaSlider17
            },
            {
                id: 3,
                image: villaSlider18
            },
            {
                id: 4,
                image: villaSlider19
            },
            {
                id: 5,
                image: villaSlider20
            },
            {
                id: 6,
                image: villaSlider21
            }
        ],
        reviews: [
            {
                id: 1,
                name: "Gaurav",
                date: "March 2023",
                image: { Avatar1 },
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },
            {
                id: 2,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },
            {
                id: 2,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            },

            {
                id: 4,
                name: "Gaurav",
                date: "March 2023",
                image: '',
                content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            }

        ]
    }

]

export const findVillaBySlug = (slug) => {
    return _.find(villaData, { id: slug });
  };


export const termsAndConditions = [
    {
        id: 1,
        title: 'Reservation and Payment',
        content: [
            {
                id: 1,
                details: 'All reservations are subject to availability.',
            },
            {
                id: 2,
                details: 'To confirm your booking, a non-refundable deposit or full payment may be required.',
            },
            {
                id: 3,
                details: 'Payment can be made through accepted credit cards, bank transfers, or other designated payment methods.'
            }
        ]
    },
    {
        id: 2,
        title: 'Check-in and Check-out:',
        content: [
            {
                id: 1,
                details: 'Check-in time is from [insert check-in time]. Check-out time is by [insert check-out time].',
            },
            {
                id: 2,
                details: 'Early check-in or late check-out may be available upon request and subject to availability and additional charges.',
            },
            {
                id: 3,
                details: 'Payment can be made through accepted credit cards, bank transfers, or other designated payment methods.'
            }
        ]
    },
    {
        id: 3,
        title: 'Villa Occupancy:',
        content: [
            {
                id: 1,
                details: 'The maximum occupancy of each villa is strictly enforced. Additional guests may require prior approval and may be subject to extra charges.',
            },
            {
                id: 2,
                details: 'To confirm your booking, a non-refundable deposit or full payment may be required.',
            },
            {
                id: 3,
                details: 'Payment can be made through accepted credit cards, bank transfers, or other designated payment methods.'
            }
        ]
    },
    {
        id: 4,
        title: 'Villa Usage:',
        content: [
            {
                id: 1,
                details: 'The villas are to be used for residential purposes only. Parties, events, and commercial activities are strictly prohibited without prior authorization.',
            },
        ]
    },
    {
        id: 5,
        title: 'Damages and Liability:',
        content: [
            {
                id: 1,
                details: 'Guests are responsible for any damages caused to the villa or its contents during their stay.',
            },
            {
                id: 2,
                details: 'The management will not be liable for any loss, theft, or damage to personal belongings during the stay.',
            },
        ]
    },
    {
        id: 6,
        title: 'Cancellation and Refunds:',
        content: [
            {
                id: 1,
                details: 'Cancellation policies may vary depending on the reservation type and period.',
            },
            {
                id: 2,
                details: 'Non-refundable deposits or payments may apply.',
            },
            {
                id: 3,
                details: 'Refunds, if applicable, are subject to the terms and conditions of the specific reservation.'
            }
        ]
    },
    {
        id: 7,
        title: 'Facilities and Amenities:',
        content: [
            {
                id: 1,
                details: 'The availability of facilities and amenities may vary between villas. Please inquire about specific amenities before booking.',
            }
        ]
    },
    {
        id: 8,
        title: 'Code of Conduct:',
        content: [
            {
                id: 1,
                details: "Guests are expected to adhere to the property's rules and regulations.",
            },
            {
                id: 2,
                details: 'Any behavior that disturbs the peace or comfort of other guests or residents may result in immediate eviction without refund.'
            }
        ]
    },
    {
        id: 9,
        title: 'Force Majeure:',
        content: [
            {
                id: 1,
                details: 'The management will not be held liable for any failure or delay in performance of its obligations due to circumstances beyond its control, including but not limited to natural disasters, acts of God, or government regulations.',
            },
            {
                id: 2,
                details: 'The management will not be liable for any loss, theft, or damage to personal belongings during the stay.',
            },
        ]
    },
    {
        id: 10,
        title: 'Jurisdiction:',
        content: [
            {
                id: 1,
                details: 'These terms and conditions are governed by the laws of (insert applicable jurisdiction).',
            },
            {
                id: 2,
                details: 'Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of [insert applicable jurisdiction].'
            },

        ]
    },
]


export const privacyPolicyData = {
    heading: 'Privacy Policy for Arlington Residencies',
    content: "At Arlington Residencies, we understand that the privacy of our customers' personal information is of utmost importance, and we value the trust you place in us by allowing us to handle this information in a careful and sensible manner. We have created this privacy policy to demonstrate our commitment to the privacy of our customers. By using our website, our dedicated telephone reservations line, and any other Arlington Residencies service, you consent and agree to the practices outlined in this statement.",
    policies: [
        {
            id: 1,
            title: 'We collect your personal information and data in the following ways:',
            description: [
                {
                    id: 1,
                    content: 'We collect your personal information and data in the following ways:'
                },
                {
                    id: 2,
                    content: 'On our website when you make or manage a booking, register or alter your details, or use the "contact us" email addresses.'
                },
                {
                    id: 3,
                    content: 'In our Customer Services department from your correspondence.'
                },
                {
                    id: 4,
                    content: 'By telephone when speaking to a call center agent or directly with a villa.'
                },
                {
                    id: 5,
                    content: 'In villas when you register at reception.'
                }

            ]
        },
        {
            id: 2,
            title: 'We use your personal information and data in the following ways:',
            description: [
                {
                    id: 1,
                    content: 'To continually improve your customer experience. We use the information to process bookings and payments, communicate with you about bookings, products, services, and promotional offers, update our records, create and maintain your Arlington Residencies account(s), and recommend products and services that might be of interest to you. We may also contact you to ask about your experience using our villas and services as part of a continual program of customer service improvement.'
                },
                {
                    id: 2,
                    content: 'To improve our website, prevent or detect fraud or abuses of our website, and enable third parties to carry out technical, logistical, or other functions on our behalf.'
                },
                {
                    id: 3,
                    content: 'To provide you with requested information or correspondence, such as a response from us to an inquiry made by you.'
                },
                {
                    id: 4,
                    content: 'To send you details of your new, amended, or canceled booking or Arlington Residencies account details.'
                },
                {
                    id: 5,
                    content: 'To contact you in the event of a change that affects your booking or any data or personal information you have provided us with, such as changes to terms & conditions of booking or this privacy policy.'
                },
                {
                    id: 6,
                    content: 'With your permission, your contact details may be used to supply information to you by telephone, email, or post, about our company. And to send you occasional promotional material, such as information about special offers which we think you might find valuable. If you do not agree to receive this information, you may opt out from receiving future information at any time; see the opt-out section of this policy.'
                },
                {
                    id: 7,
                    content: 'We may also provide aggregate statistics about our customers, sales, traffic patterns, and related site information to reputable third parties, but these statistics will include no personally identifying information.'
                },
                {
                    id: 8,
                    content: 'We might receive information about you from other sources and add it to our account information. This ensures that the quality of data we have on your account(s) is maintained properly.'
                }
            ]
        }
    ],
    cookies: [
        {
            id: 1,
            content: 'At Arlington Residencies, we use cookies to enhance your user experience, improve the performance of our website, and provide personalized content and advertising.'

        },
        {
            id: 2,
            content: 'By using our website, you consent to the use of cookies as described in our privacy policy. You can manage your cookie preferences through your web browser settings. Please note that disabling cookies may impact your user experience and limit your ability to access certain features of our website.'
        },
        {
            id: 3,
            content: 'We also use third-party services such as Google Analytics and Facebook Pixel that may use cookies on our behalf. Please refer to their respective privacy policies for more information on their data collection practices and how to opt-out.'
        }
    ],
    nonDisclosureToThirdParties: [
        {
            id: 1,
            content: 'The information and data we collect is important for Arlington Residencies, and we would not want to share this with anyone else. Unless we have your express consent, we will never disclose, rent, trade, or sell your personal data to any third parties for their marketing or mailing purposes.'
        },
        {
            id: 2,
            content: 'We do disclose or transfer your data or personal information to other companies, data processors, or agents employed by us to perform any necessary functions on our behalf, but they are bound by our privacy policy and may not use this information for their own purposes. In the event that Arlington Residencies or any part of its business is sold to or integrated with another business, Arlington Residencies may disclose your personal information to the new owners (and their professional advisers on the transaction) to be used by the new owners and their group of companies in the same ways as set out in this Privacy Policy, including to continue providing you with the same services and marketing information services as are currently provided by Arlington Residencies.'
        }
    ],
    userConsent: [
        {
            id: 1,
            content: 'When you use the Arlington Residencies website, you are giving your consent to allow us to collect, store, and process your personal information in accordance with this privacy policy. If we make any changes to our privacy policy, we will post them on our website to ensure that you are always aware of what information we collect, how we use it, and when we disclose it.'
        },
        {
            id: 2,
            content: 'At Arlington Residencies, we value your privacy and respect your wishes regarding electronic marketing communications. We will always seek your permission before sending any electronic marketing information. This means that you will only receive information from us that you have explicitly given us permission to send and that you are willing to receive.'
        },
        {
            id: 3,
            content: 'If at any time you wish to change your contact preferences, you can do so easily. Simply send an email to bookings@arlingtonresidencies.com with your name, address, and, if applicable, your booking confirmation number. If you receive promotional emails from us, you can also click the "unsubscribe" link at the bottom of the email to stop receiving these communications. However, please note that we may still contact you about important information related to your booking to ensure that your stay with us goes smoothly.'
        },
        {
            id: 4,
            content: 'We may receive additional information about you from other sources, such as the Mail Preference Service, and add it to your account information to maintain the quality of our data. If you ever wish to view, delete, or amend any of the personal information we have on file for you, simply contact us in writing at the address provided above. While there is no charge for amending or deleting personal information, we may charge a fee for viewing information held by us.'
        }
    ]



}

export const faqData = [
    {
        id: 1,
        title: 'How do I make a reservation?',
        content: 'To make a reservation at Arlington Residencies, you can visit our website and select your desired dates, villa type, and number of guests. Alternatively, you can call our reservations team directly and they will assist you in making a booking.'
    },
    {
        id: 2,
        title: 'Can I cancel my reservation?',
        content: 'Yes, you can cancel your reservation with Arlington Residencies. However, please note that our cancellation policy varies depending on the type of booking you have made. Please refer to our website or contact our reservations team for more information.'
    },
    {
        id: 3,
        title: 'What is included in the price of my villa rental?',
        content: 'Your villa rental at Arlington Residencies includes a fully equipped kitchen, daily housekeeping, access to our amenities and swimming pool, and complimentary Wi-Fi. Some villa types may also include additional amenities such as private pool, additional indoor games, heated swimming pool'
    },
    {
        id: 4,
        title: 'Do you offer airport transportation?',
        content: 'Yes, we offer airport transportation services for an additional fee. Please contact our reservations team to arrange your transportation.'
    },
    {
        id: 5,
        title: 'What is your check-in and check-out policy?',
        content: 'Our standard check-in time is 12:00 PM and check-out time is 11:00 AM. However, we understand that travel plans may vary and we are happy to accommodate early check-in or late check-out requests when possible.'
    },
    {
        id: 6,
        title: 'Do you offer any additional services or amenities?',
        content: 'Yes, we offer a variety of additional services and amenities such as private chef services, spa treatments, live BBQ, Live Singer, Laundry Services, Drivers’ and Maid’s accommodation and grocery delivery. Please contact our concierge team for more information and to arrange your desired services.'
    },
    {
        id: 7,
        title: 'Is smoking allowed in the villas?',
        content: 'No, smoking is not permitted in any of our villas. We have designated outdoor smoking areas available for your convenience.'
    },
    {
        id: 8,
        title: 'Are pets allowed in the villas?',
        content: 'Yes, we allow pets.'
    },
    {
        id: 9,
        title: 'Do you offer group discounts?',
        content: 'Yes, we offer group discounts for bookings of five or more villas. Please contact our reservations team for more information and to receive a personalized quote for your group.'
    },
    {
        id: 10,
        title: 'What is your privacy policy?',
        content: 'Our privacy policy can be found on our website and outlines how we collect, use, and protect your personal information. If you have any questions or concerns about our privacy policy, please contact us directly.'
    },
    {
        id: 11,
        title: 'Can I cancel my reservation?',
        content: 'Yes, you can cancel your reservation, but cancellation policies vary depending on the rate and dates of your reservation. Please refer to your booking confirmation for details or contact our reservations team.'
    },
    {
        id: 12,
        title: 'Is parking available at the villas?',
        content: 'Yes, we provide on-site parking for our guests free of charge.'
    },
    {
        id: 13,
        title: 'Is breakfast included in the room rate?',
        content: 'It depends on the rate and package you have booked. Please refer to your booking confirmation or contact our reservations team for more information.'
    },
    {
        id: 14,
        title: 'Can I arrange for additional cleaning services during my stay?',
        content: 'Yes, we can arrange for additional cleaning services for an additional charge. Please contact our concierge team for more information.'
    },
    {
        id: 15,
        title: 'Is there a restaurant at the villas?',
        content: 'No, we do not have a restaurant on-site, but there are plenty of dining options nearby. Our concierge team can provide recommendations or order in and get the food delivered to the villa.'
    },
    {
        id: 16,
        title: 'Are there any recreational activities available at the villas?',
        content: 'Yes, we have a variety of recreational activities available for our guests, including swimming pools, tennis, and indoor games.'
    },
    {
        id: 17,
        title: 'Is there a minimum stay requirement at Arlington Luxury Villas?',
        content: 'Yes, there is a minimum stay requirement of one nights for all villa types.'
    },
    {
        id: 18,
        title: 'Is there a deposit required to make a reservation?',
        content: ' Yes, we require a deposit at the time of booking to secure your reservation. The amount of the deposit varies depending on the length of your stay and the villa type.'
    },
    {
        id: 19,
        title: 'What is your cancellation policy?',
        content: 'Our cancellation policy varies depending on the season and villa type. Please refer to the reservation confirmation email or contact our reservations team for details.'
    },
    {
        id: 20,
        title: 'Is smoking allowed at Arlington Luxury Villas?',
        content: 'No, our villas are strictly non-smoking. Smoking is only allowed in designated outdoor areas.'
    },
    {
        id: 21,
        title: 'Is there a restaurant on site?',
        content: 'No, we do not have a restaurant on site. However, our villas are equipped with a fully equipped kitchen where you can prepare your own meals or hire a cook. Please contact our concierge team.'
    },
    {
        id: 22,
        title: 'Is there a grocery store nearby?',
        content: 'Yes, there is a grocery store within walking distance from our villas.'
    },
    {
        id: 23,
        title: 'Do you offer housekeeping services?',
        content: 'Yes, we offer daily housekeeping services for our guests.'
    },
    {
        id: 24,
        title: 'Is there a pool at Arlington Residencies?',
        content: 'Yes, we have a private pool in each of our villas.'
    },
    {
        id: 25,
        title: 'What activities are available near Arlington Residencies?',
        content: 'There are several activities available near our villas, including Tennis court, golf, and horseback riding, Mapro Garden, strawberry farm visits. Our concierge team can assist you in arranging any activities you are interested in.'
    },
]