import React, { useState, useEffect } from "react";
import Banner from "../assets/contactBanner.jpg";
import { Breadcrumbs } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { contactPage } from "../../Data";
import { Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import {
  svgVariants,
  textVariants,
  pathVariants,
  textFadeIn,
} from "../component/motion";
import Location from "../assets/location.jpg";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import config from "../config/config";
import Joi from "joi";
import http from "../helpers/http";
import CTASvg from '../common/CTASvg';
const Contact = () => {
  const [contact, setContact] = useState(contactPage);

 
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "/forms/contact";
  let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    first_name: Joi.string().trim().required().label("First Name"),
    last_name: Joi.string().trim().required().label("Last Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
      phone: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .messages({
        "string.pattern.base": `Contact number must have 10 digits.`,
      })
      .required()
      .label("Contact number"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

  

    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      // navigate("/thank-you");
      setValues({
        first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
      });
      navigate("/thankyou");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  return (
    <>
      <section className="main_section ">
        <div className="main_bg contactBg">
          <motion.div
            className="contact_breadcrumbs "
            variants={textVariants("left", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <Breadcrumbs separator="|" aria-label="breadcrumb">
              <Link to="/">HOME </Link>
              <span>CONTACT US</span>
            </Breadcrumbs>
          </motion.div>
          <motion.div
            className="main_heading"
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <h1>CONTACT US</h1>
          </motion.div>
        </div>

        <section className="contact_sec_two">
          <div className="contact_svg_image svg_path_con">
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              className="contactSvg"
              width="94.32291666666667vw"
              height="51.58177083333333vw"
              viewBox="0 0 1811.603 990.972"
              variants={svgVariants("0%", "-17%")}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
            >
              <motion.path
                id="BG_line"
                data-name="BG line"
                variants={pathVariants(2)}
                d="M1991.7,1524.342s44.3,597.06,1200.916,587.883c345.741.79,422.175,33.843,512.371,87.995"
                transform="translate(-2245.472 -789.305) rotate(-15)"
                fill="none"
                stroke="#707070"
                stroke-width="1"
              />
            </motion.svg>
          </div>
          <div className="contact-s" style={{ position: "" }}>
            <motion.div
              className="contact_sec_heading"
              variants={textVariants("down", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h1>CONTACT INFORMATION</h1>
            </motion.div>

            <div className="map_info_section d-flex">
              <div className="">
                <div className="contact_left_section">
                  <motion.svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="49.58649093904448vw"
                    variants={svgVariants("0%", "0%")}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.1 }}
                    height="27.059857221306974vw"
                    viewBox="0 0 903.997 493.829"
                  >
                    <g
                      id="Group_668"
                      data-name="Group 668"
                      transform="translate(-139.481 -1784.5)"
                    >
                      <motion.path
                        id="Path_940"
                        data-name="Path 940"
                        variants={pathVariants(3)}
                        d="M8362.77,2062.473,8324,1989.935s13.757-95.05,23.762-111.308,41.272-62.533,86.3-90.047,207.607-106.306,228.869-118.812S8736.716,1636,8736.716,1636h86.295s155.082,52.527,216.364,58.781,82.543,8.755,83.793,12.507,77.541,68.786,77.541,68.786l26.264,83.794s-8.754,116.311-26.264,130.068-77.541,43.773-77.541,43.773-65.033,13.757-113.809,22.512-110.058,17.509-157.583,15.008-141.325-20.01-188.85-15.008-98.8,50.026-210.109,72.538c-15.008-36.269-16.259-77.54-57.53-66.284H8362.77Z"
                        transform="translate(-8184 149)"
                        fill="#081d1a"
                        stroke="#ba8d65"
                        stroke-width="1"
                      />
                      <motion.text
                        variants={textFadeIn(3, 0.16)}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.1 }}
                        id="Mahabaleshwar"
                        transform="translate(578 2040)"
                        fill="#FFFFFF"
                        font-size="32"
                        font-family="RedHatDisplay-Regular, Red Hat Display"
                        opacity="0.16"
                      >
                        <tspan x="-109.488" y="0">
                          Mahabaleshwar
                        </tspan>
                      </motion.text>
                      <a href="#" target="_blank">
                        <motion.text
                          variants={textFadeIn(4, 1)}
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true, amount: 0.1 }}
                          id="Panchgani"
                          transform="translate(869 2040)"
                          fill="#ba8d65"
                          font-size="32"
                          font-family="RedHatDisplay-Regular, Red Hat Display"
                        >
                          <tspan x="0" y="0">
                            Panchgani
                          </tspan>
                        </motion.text>
                      </a>
                    </g>
                  </motion.svg>
                  <a href="#" target="_blank">
                    <motion.img
                      className="location_icon_img"
                      id="placeholder"
                      variants={textFadeIn(4, 1)}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.1 }}
                      src={Location}
                    />
                  </a>
                </div>
              </div>
              <div className="">
                <div className="contact_right_section">
                  <motion.div
                    className="section_one_right"
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h3>VISIT</h3>
                    <p>
                      <a href="#" target="_blank">
                        {contact[0]?.address}
                      </a>
                    </p>
                  </motion.div>
                  <motion.div
                    className="section_one_right mt-4"
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h3> WRITE</h3>
                    <p>
                      <a href={`mailto:${contact[0]?.email}`}>
                        {contact[0]?.email}
                      </a>
                    </p>
                  </motion.div>
                  <motion.div
                    className="section_two_right mt-4"
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h3>CALL</h3>
                    <p>
                      <a href={`tel:${contact[0]?.phone}`}>{contact[0]?.phone}</a>
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact_sec_three">
          <div className="">
            <motion.div
              className="contact_sec_three_heading"
              variants={textVariants("down", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h1>HAVE A QUESTION? WRITE US</h1>
            </motion.div>

            <motion.Form
              className="contact_form"

           
              onSubmit={handleSubmit}
              
              variants={textVariants("top", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <Form.Group className="form-group_ ">
                <Form.Label>FIRST NAME*</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  
                />
               
                  <span className="err_msg">{formError.first_name}</span>
                
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>LAST NAME*</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                />
               <span className="err_msg">{formError.last_name}</span>
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>PHONE*</Form.Label>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Your Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
                 <span className="err_msg">{formError.phone}</span>
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>E-MAIL*</Form.Label>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Your e-mail"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.email}</span>
              </Form.Group>
              <Form.Group className="w-100">
                <Form.Label>MESSAGE</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="Enter your message here"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.message}</span>
              </Form.Group>

              <button className="contact_form_submit_button" type="submit">
                <div
                  className="action active-cursor decor no-transition"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: " none",
                    transform: "translate(0px, 0px)",
                    opacity: "1",
                  }}
                >
                  <p className="m-0">Send Request</p>

                  <CTASvg />
                </div>
              </button>
            </motion.Form>
          </div>
        </section>
      </section>
    </>
  );
};

export default Contact;
