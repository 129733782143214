import React, { useState, useEffect } from 'react'
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import mumbai from '../assets/mumbai.jpg';
import pune from '../assets/pune.jpg';
import highway from '../assets/highway.jpg';
import { svgVariants, textVariants, pathVariants } from '../component/motion';
import rainFall from '../assets/rainFall.jpg';
import winter from '../assets/winter.jpg';
import summer from '../assets/summer.jpg';
import aeroplan from '../assets/aeroplan.jpg';
import train from '../assets/train.jpg';
import hightway1 from '../assets/highway1.jpg';
import { locationData } from '../../Data';


const Location = () => {


    const handleMouseMove = (event) => {
        const r = event.currentTarget.getBoundingClientRect();

        event.currentTarget.style.setProperty('--x', event.clientX - (r.left + Math.floor(r.width / 2)));
        event.currentTarget.style.setProperty('--y', event.clientY - (r.top + Math.floor(r.height / 2)));
    };

    const handleMouseLeave = (event) => {
        event.currentTarget.style.setProperty('--x', 0)
        event.currentTarget.style.setProperty('--y', 0)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>

            <section className='main_section'>
                <div className='main_bg locationBg'>
                    <motion.div className='contact_breadcrumbs ' variants={textVariants('left', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <Breadcrumbs separator="|" aria-label="breadcrumb">
                            <Link to="/" >HOME </Link>
                            <span>LOCATION</span>
                        </Breadcrumbs>
                    </motion.div>
                    <motion.div className='main_heading' variants={textVariants('up', 0.2)}
                        initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.1 }} transition={{ duration: 0.5 }}>
                        <h1>LOCATION</h1>
                    </motion.div>
                </div>
            </section>


            <div className='location-s hero_s'>
                <div className='location-d d-block villas_maindesign_sec '>
                    <div className='hero-d villas_design_s'>
                        <h1>{locationData.heading}</h1>
                    </div>
                    <div className='content-s d-flex justify-content-end'>
                        <div className='content-d'>
                            <p>
                                {locationData.content}
                            </p>
                        </div>
                    </div>
                </div>


                <motion.svg className='locationSvg'
                    variants={svgVariants('0%', '0%')}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.1 }}
                    xmlns="http://www.w3.org/2000/svg" width="107.39583333333333vw" height="65.20833333333333vw" viewBox="0 0 2063.021 1252.666">
                    <motion.path id="Path_941"
                        variants={pathVariants(3)}
                        data-name="Path 941" d="M13870.16,1867.331s-173.293,80.683-461.021,89.89-821.95-81.056-1216.282,559.373-384.985,602.008-384.985,602.008" transform="translate(-11807.35 -1866.878)" fill="none" stroke="#707070" stroke-width="1" />
                </motion.svg>
            </div>

            <div className='location-inner-s'>
                <div className='section_gateway'>
                    <div className='gateway-s d-flex'>
                        <div className='left-d '>
                            <h2>
                                {locationData.gatewayData.title}
                            </h2>
                            <p>
                                {locationData.gatewayData.description}
                            </p>
                        </div>
                        <div className='right-d '>
                            <div className='temp_d d-flex'>
                                <div className='rainfall'>
                                    <img src={locationData.gatewayData.weather.rainfall.image} width={40} height={40} /><br></br>
                                    <span>
                                        {locationData.gatewayData.weather.rainfall.name}
                                    </span>
                                    <p>
                                        {locationData.gatewayData.weather.rainfall.month}<br></br>{locationData.gatewayData.weather.rainfall.temp}
                                    </p>

                                </div>
                                <div className='v-line' style={{ height: "8.59506vw" }}></div>
                                <div className='winter'>
                                    <img src={locationData.gatewayData.weather.winter.image} width={40} height={40} /><br></br>
                                    <span>
                                        {locationData.gatewayData.weather.winter.name}
                                    </span>
                                    <p>
                                        {locationData.gatewayData.weather.winter.month}<br></br>{locationData.gatewayData.weather.winter.temp}
                                    </p>

                                </div>
                                <div className='v-line' style={{ height: "8.59506vw" }}></div>
                                <div className='summer'>
                                    <img src={locationData.gatewayData.weather.summer.image} width={40} height={40} /><br></br>
                                    <span>
                                        {locationData.gatewayData.weather.summer.name}
                                    </span>
                                    <p>
                                        {locationData.gatewayData.weather.summer.month}<br></br>{locationData.gatewayData.weather.summer.temp}
                                    </p>

                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='location-s connectivity_d'>
                <div className='location-d d-flex'>
                    <div className='hero-d connection_head'>
                        <h1>{locationData.data.heading}</h1>
                    </div>
                    <div className='content-s d-flex justify-content-end connection_head-s'>
                        <div className='content-d '>
                            <p>
                                {locationData.data.description}
                            </p>
                        </div>
                    </div>
                </div>


                <motion.svg className='connectivitySvg'
                    variants={svgVariants('0%', '0%')}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.1 }}
                    xmlns="http://www.w3.org/2000/svg" width="50vw" height="28.541666666666668vw" viewBox="0 0 960.843 548.784">
                    <motion.path id="BG_line"
                        variants={pathVariants(2)}
                        data-name="BG line" d="M1991.7,1524.342s23.365,338.175,633.341,332.978c182.338.447,222.647,19.169,270.215,49.84" transform="translate(-2245.472 -943.808) rotate(-11)" fill="none" stroke="#707070" stroke-width="1" />
                </motion.svg>


            </div>

            <div className='location-s'>
                <div className='Amenities-inner'>
                    {locationData.data.location.map((location, index) => {
                        if ((index + 1) % 2 == 0) {
                            return (

                                <div className='Amenities-inner-d odd_d d-flex align-items-center justify-content-end '>

                                    <div className='content-d'>
                                        <span className='d-flex align-items-center gap-2' >
                                            <img src={location.icon} />
                                        </span>
                                        <h1 className='heading'>
                                            {location.name}
                                        </h1>
                                        <div>

                                            <motion.p>
                                                {location.distance}
                                            </motion.p>


                                        </div>

                                    </div>
                                    <div className='img-d'>
                                        <span className='slide--current'
                                            onMouseMove={handleMouseMove}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <div className="slide__image-wrapper">
                                                <img src={location.image} style={{ width: '100%' }} />
                                            </div>
                                        </span>

                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='Amenities-inner-d d-flex align-items-center'>
                                    <div className='img-d'>
                                        <span className='slide--current'
                                            onMouseMove={handleMouseMove}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <div className="slide__image-wrapper">
                                                <img src={location.image} style={{ width: '100%' }} />
                                            </div>
                                        </span>
                                    </div>
                                    <div className='content-d'>
                                        <span className='d-flex align-items-center gap-2' >
                                            <img src={location.icon} className='location_icons_img' />
                                        </span>
                                        <h1 className='heading'>
                                            {location.name}
                                        </h1>
                                        <div>
                                            <motion.p>
                                                {location.distance}
                                            </motion.p>
                                        </div>

                                    </div>

                                </div>
                            )
                        }

                    })}

                    {/* 
                    <div className='Amenities-inner-d d-flex align-items-center '>
                        <div className='img-d'>
                            <span className='slide--current'
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="slide__image-wrapper">
                                    <img src={pune} style={{ width: '100%' }} />
                                </div>
                            </span>

                        </div>
                        <div className='content-d'>
                            <span className='d-flex align-items-center gap-2' >
                                <img src={train} />
                            </span>
                            <h1 className='heading'>
                                PUNE RAILWAY STATION
                            </h1>
                            <div>

                                <motion.p>
                                    60 Mins drive from Pune Station
                                </motion.p>


                            </div>

                        </div>

                    </div> */}

                </div>
            </div>
        </>
    )
}

export default Location