import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import 'swiper/css/zoom';
import { EffectCoverflow, Pagination, Zoom, Autoplay, Navigation } from "swiper";

const TabSlider = ({ sliderData }) => {

    const [swiper, setSwiper] = useState(null);

    return (
        <>
            {/* 91.46666666666667vw */}
            {/* 104vw */}

            <div className="wrapper" style={{ position: 'relative' }}>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={1}
                    loop={true}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 200,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    initialSlide={0}
                    autoplay={false}
                    speed={500}
                    navigation={{
                        nextEl: ".image-swiper-button-next",
                        prevEl: ".image-swiper-button-prev",
                        disabledClass: "swiper-button-disabled"
                    }}
                    spaceBetween={50}
                    pagination={false}

                    modules={[Navigation, Pagination, Autoplay]}
                    className="mySwiper tab_slider"
                    onSwiper={(swiper) => {
                        setSwiper(swiper);
                    }}
                    // onActiveIndexChange={(swiper) => {
                    //     setActiveSlider(swiper.activeIndex);
                
                    // }}
                    breakpoints={{
                        30: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 1.5,

                        },
                        1024: {
                            slidesPerView: 1.5,

                        },
                    }}



                >

                    {/* {sliderData?.map((Data) => {
                        return ( */}

                    <SwiperSlide className="Swiper">
                        <span className='slide--current'
                        // onMouseMove={handleMouseMove}
                        // onMouseLeave={handleMouseLeave}
                        >
                            <div className="slide__image-wrapper">
                                <img src={sliderData.image1} className='tab_slider_img' />
                            </div>
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className="Swiper">
                        <span className='slide--current'
                        // onMouseMove={handleMouseMove}
                        // onMouseLeave={handleMouseLeave}
                        >
                            <div className="slide__image-wrapper">
                                <img src={sliderData.image2} className='tab_slider_img' />
                            </div>
                        </span>
                    </SwiperSlide>
                    {/* )
                    })} */}

                    <div className="swiper-button image-swiper-button-next">
                        <svg id="Group_7024" data-name="Group 7024" xmlns="http://www.w3.org/2000/svg" width="9.333333333333334vw" height="9.333333333333334vw" viewBox="0 0 35 35">
                            <g id="Group_630" data-name="Group 630" transform="translate(6.652 26.36) rotate(-90)">
                                <path id="Path_922" data-name="Path 922" d="M0,0S7.865,3.744,8.705,8.877" transform="translate(0 12.818)" fill="none" stroke="#fff" stroke-width="1" />
                                <path id="Path_923" data-name="Path 923" d="M8.945,0S.035,5.243,0,8.843" transform="translate(8.774 12.818)" fill="none" stroke="#fff" stroke-width="1" />
                                <path id="Path_924" data-name="Path 924" d="M0,0V21.627" transform="translate(8.775 0)" fill="none" stroke="#fff" stroke-width="1" />
                            </g>
                            <g id="Ellipse_3" data-name="Ellipse 3" fill="none" stroke="#fff" stroke-width="1">
                                <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                                <circle cx="17.5" cy="17.5" r="17" fill="none" />
                            </g>
                        </svg>
                    </div>
                    <div className="swiper-button image-swiper-button-prev">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.333333333333334vw" height="9.333333333333334vw" viewBox="0 0 35 35">
                            <g id="Group_6970" data-name="Group 6970" transform="translate(-921 -2783)">
                                <g id="Group_630" data-name="Group 630" transform="translate(927.652 2809.36) rotate(-90)">
                                    <path id="Path_922" data-name="Path 922" d="M0,8.877S7.865,5.132,8.705,0" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="1" />
                                    <path id="Path_923" data-name="Path 923" d="M8.945,8.843S.035,3.6,0,0" transform="translate(8.774 0.034)" fill="none" stroke="#fff" stroke-width="1" />
                                    <path id="Path_924" data-name="Path 924" d="M0,21.627V0" transform="translate(8.775 0.069)" fill="none" stroke="#fff" stroke-width="1" />
                                </g>
                                <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(921 2783)" fill="none" stroke="#fff" stroke-width="1">
                                    <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                                    <circle cx="17.5" cy="17.5" r="17" fill="none" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </Swiper>
            </div >


        </>
    )
}

export default TabSlider