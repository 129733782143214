import React, { useState, useEffect, useRef } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { villaData, findVillaBySlug } from "../../Data";
import { Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { svgVariants, textVariants, pathVariants } from "../component/motion";

import star from "../assets/star.jpg";
import location from "../assets/locationIcon.png";

import Avatar from "@mui/material/Avatar";

import img360 from "../assets/view360.jpg";

import SingleVillaSlider from "../common/Slider/SingleVillaSlider";
import Modal from "react-bootstrap/Modal";
import CTASvg from "../common/CTASvg";
import WriteAReview from "../common/WriteAReview";
import config from "../config/config";
import Joi from "joi";
import http from "../helpers/http";
import helper from "../helpers/helper";
import Rating from "@mui/material/Rating";

const SingleVilla = () => {
  const { id } = useParams();

  const [modalShow, setModalShow] = useState(false);

  const [data, setData] = useState(villaData);
  const [villa, setVilla] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState(4);
  const [loadMoreVisible, setLoadMoreVisible] = useState(true);
  const [totalReviews, setTotalReviews] = useState(0);
  const [avgRating, setAvgRating] = useState(0);

  console.log(data?.filter((data) => data.id == id)[0].title);

  useEffect(() => {
    const villaData = findVillaBySlug(id);
    setVilla(villaData);
  }, [id]);
  useEffect(() => {
    if (villa) {
      console.log("fetching reviews");
      fetchReviews();
    }
  }, [villa]);

  const handleLoadMore = () => {
    console.log("load more ");

    setReviewsCount((prev) => {
      return prev + 4;
    });
    fetchReviews(reviewsCount);
  };

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "/forms/project-enquiry";
  let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    first_name: Joi.string().trim().required().label("First Name"),
    last_name: Joi.string().trim().required().label("Last Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .messages({
        "string.pattern.base": `Contact number must have 10 digits.`,
      })
      .required()
      .label("Contact number"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, {
      ...values,
      project: villa?.title,
    });

    if (data) {
      // redirect
      // navigate("/thank-you");
      setValues({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
      });

      setModalShow(false);
      navigate("/thankyou");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeReview = () => {
    setExpanded(!expanded);
  };

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleMouseMove = (event) => {
    const r = event.currentTarget.getBoundingClientRect();

    event.currentTarget.style.setProperty(
      "--x",
      event.clientX - (r.left + Math.floor(r.width / 2))
    );
    event.currentTarget.style.setProperty(
      "--y",
      event.clientY - (r.top + Math.floor(r.height / 2))
    );
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.setProperty("--x", 0);
    event.currentTarget.style.setProperty("--y", 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchReviews = async (offset = 0) => {
    let url = config.api_url + "/reviews/" + villa?._id;
    if (offset) {
      url = url + `?offset=${offset}`;
    }

    const { data } = await http.get(url);
    setTotalReviews(data?.totalCount);
    setAvgRating(data?.rating);
    if (offset) {
      setReviews((prevState) => {
        return [...prevState, ...data?.reviews];
      });
    } else {
      setReviews(data?.reviews);
    }
    console.log({ reviewsCount });
    if (data?.reviews?.length < 4) {
      console.log("load more disable");
      setLoadMoreVisible(false);
    } else if (offset < data?.totalCount) {
      setLoadMoreVisible(true);
      console.log("load more enable");
    }
  };
  if (!villa) {
    return "";
  }
  return (
    <>
      {/* {data
        ?.filter((data) => data.id == id)
        .map((data) => {
          return (
            
          );
        })} */}
      <section className="main_section single_villa_section">
        <div
          className="main_bg singleVilla_bg"
          style={{ backgroundImage: `url(${villa.banner})` }}
        >
          <motion.div
            className="contact_breadcrumbs"
            variants={textVariants("left", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <Breadcrumbs separator="|" aria-label="breadcrumb">
              <Link to="/">HOME </Link>
              <Link to="/villas">VILLAS</Link>
              <span>{villa.title}</span>
            </Breadcrumbs>
          </motion.div>
          <motion.div
            className="main_heading"
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <h1>{villa.title}</h1>
          </motion.div>
        </div>

        <div className="Amenities-inner single_villa">
          <div className="single-villa d-flex align-items-center">
            <div className="img-d">
              <span
                className="slide--current"
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              >
                <div className="slide__image-wrapper">
                  <img src={villa.centerImage} style={{ width: "100%" }} />
                </div>
              </span>
            </div>
            <div className="content-d">
              <h1 className="heading">{villa.title}</h1>
              <div className="villa_location_ratting">
                <span className="location">
                  <img src={location} width={20} height={20} />
                  {villa.location}
                </span>
                <span className="_location">
                  <Link to={villa.locationUrl} target="_blank">
                    See location
                  </Link>
                </span>
                <span className="reviews">
                  <img src={star} width={20} height={20} /> {avgRating || 0} ·{" "}
                  <span>
                    <Link onClick={() => scrollToSection()}>
                      {totalReviews || 0} reviews
                    </Link>
                  </span>
                </span>
              </div>

              <div>
                <motion.p className="">{villa.content}</motion.p>

                {/* <span className='d-flex align-items-center gap-2' >
                    <img src={download} width={30} height={30} /> RELAXING SPA MENU
                </span> */}
              </div>

              <div className="specifications-d">
                <h4 className="specifications">SPECIFICATIONS</h4>
                <div>
                  <div className="specifications-content d-flex justify-content-between align-items-center">
                    <span>
                      <p>Plot Size</p>
                      <p style={{ marginTop: "0.4942339373970346vw" }}>
                        {villa.plotSize}
                      </p>
                    </span>
                    <div className="v-line"></div>
                    <span>
                      <p>Built Area</p>
                      <p style={{ marginTop: "0.4942339373970346vw" }}>
                        {villa.builtArea}
                      </p>
                    </span>
                    <div className="v-line"></div>
                    <span>
                      <p>Bedrooms</p>
                      <p style={{ marginTop: "0.4942339373970346vw" }}>
                        {villa.bedrooms}
                      </p>
                    </span>
                  </div>
                </div>
                <div className="price-d">
                  <div>
                    <span className="price">Rs. {villa.price} / Night</span>
                    <br></br>
                    {villa.gst && <span className="taxes">
                      * {villa.gst}% tax will be applicable
                    </span>}
                  </div>
                </div>

                {/* <div className='booking_d_'>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                           
                            <DateField placeholder="CHECK-IN" />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                           
                            <DateField placeholder="CHECKOUT" />
                        </LocalizationProvider>

                     
                        <Form.Select aria-label="Default select example">
                            <option selected disabled>GUEST</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>

                    </div> */}

                <div className="section_four_button_sect single_villas_btn_s_d">
                  {/* <div className="action active-cursor decor no-transition" style={{ translate: "none", rotate: "none", scale: " none", transform: "translate(0px, 0px)", opacity: '1' }}>
                            <p className='m-0' ><Link to="/contact">Book your stay</Link></p>
                            <div className="circle-desc"><svg width="50" height="50" viewport="0 0 25 25" version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                    strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                                <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                    strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                            </svg> <svg width="50" height="50" viewport="0 0 25 25" version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                    <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                        strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                                </svg></div> <svg width="38" height="43" viewBox="0 0 38 43" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" className="circle-mob">
                                <path opacity="0.2"
                                    d="M1 10.6471C4.44118 4.85363 10.5556 1 17.525 1C28.2807 1 37 10.1782 37 21.5C37 32.8218 28.2807 42 17.525 42C10.5556 42 4.44118 38.1464 1 32.3529"
                                    stroke="currentColor" strokeWidth={0.5} />
                            </svg>
                        </div> */}
                  <div
                    className="action active-cursor decor no-transition"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: " none",
                      transform: "translate(0px, 0px)",
                      opacity: "1",
                    }}
                    onClick={() => setModalShow(true)}
                  >
                    <p className="m-0">Enquire Now</p>

                    <CTASvg />
                  </div>

                  {/* <div className="action active-cursor decor no-transition enq_btn_d" style={{ translate: "none", rotate: "none", scale: " none", transform: "translate(0px, 0px)", opacity: '1' }}
                            onClick={() => setModalShow(true)}
                        >
                            <p className='m-0 enq_btn_text' >Enquire Now</p>
                            <div className="circle-desc"><svg width="50" height="50" viewport="0 0 25 25" version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                    strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                                <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                    strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                            </svg> <svg width="50" height="50" viewport="0 0 25 25" version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                    <circle strokeWidth={0.5} r="24.5" cx="25" cy="25" fill="transparent" strokeDasharray={155}
                                        strokeDashoffset={0} style={{ stroke: "currentcolor" }} />
                                </svg></div> <svg width="38" height="43" viewBox="0 0 38 43" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" className="circle-mob">
                                <path opacity="0.2"
                                    d="M1 10.6471C4.44118 4.85363 10.5556 1 17.525 1C28.2807 1 37 10.1782 37 21.5C37 32.8218 28.2807 42 17.525 42C10.5556 42 4.44118 38.1464 1 32.3529"
                                    stroke="currentColor" strokeWidth={0.5} />
                            </svg>
                        </div> */}
                </div>
              </div>
            </div>
          </div>

          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="46.22447916666667vw"
            height="25.520833333333332vw"
            viewBox="0 0 888.34 490.315"
            className="singleVillaSvg"
            variants={svgVariants("0%", "0%")}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
          >
            <motion.path
              id="BG_line"
              data-name="BG line"
              d="M1991.7,1524.342s21.692,296.665,587.978,292.1c169.278.393,206.7,16.816,250.861,43.723"
              transform="translate(-2245.472 -956.157) rotate(-11)"
              fill="none"
              stroke="#707070"
              stroke-width="1"
              variants={pathVariants(3)}
            />
          </motion.svg>
        </div>

        <div className="Amenities-inner single_villa singleVilla-s2">
          <div className="amenities single_amen_s">
            <h1 className="m-0">Amenities</h1>
            <div className="maincontent_facility">
              <div
                className="d-flex align-items-center justify-content-between flex-wrap "
                style={{ rowGap: "6.1729818780889625vw" }}
              >
                {villa.facilities?.map((data) => {
                  return (
                    <>
                      <div className="facility d-flex flex-column align-items-center">
                        <img src={data.image} width={80} height={80} />
                        <span className="pt-2">{data.name}</span>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="108.54166666666667vw"
            height="67.10833333333333vw"
            viewBox="0 0 2084.569 1288.48"
            className="singleVillaSvg2"
            variants={svgVariants("0%", "0%")}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
          >
            <motion.path
              id="Path_941"
              data-name="Path 941"
              d="M13870.16,1867.331s-173.293,80.683-461.021,89.89-821.95-81.056-1216.282,559.373-384.985,602.008-384.985,602.008"
              transform="matrix(1, 0.017, -0.017, 1, -11751.108, -2072.66)"
              fill="none"
              stroke="#707070"
              stroke-width="1"
              variants={pathVariants(3)}
            />
          </motion.svg>
        </div>

        {/* slider  */}

        <div
          className="villa_slider_section"
        //  style={{ paddingTop: '12.395833333333334vw' }}
        >
          <div className="villas_section singleVilla_spider">
            <SingleVillaSlider sliderData={villa.villaSlider} />
          </div>

          <div className="text-center">
            <button className="backToVillas">
              <Link to="/villas">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.875vw"
                  height="1.5625vw"
                  viewBox="0 0 20.788 17.582"
                >
                  <g
                    id="Group_664"
                    data-name="Group 664"
                    transform="translate(0.121 17.26) rotate(-90)"
                  >
                    <path
                      id="Path_922"
                      data-name="Path 922"
                      d="M0,8.456S7.493,4.889,8.293,0"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_923"
                      data-name="Path 923"
                      d="M8.521,8.423S.033,3.429,0,0"
                      transform="translate(8.358 0.033)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_924"
                      data-name="Path 924"
                      d="M0,20.6V0"
                      transform="translate(8.359 0.065)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                BACK TO VILLAS
              </Link>
            </button>
          </div>

          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="106.97182291666667vw"
            height="63.86739583333333vw"
            viewBox="0 0 2053.859 1226.254"
            className="singleVillaSliderSvg"
            variants={svgVariants("0%", "0%")}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
          >
            <motion.path
              id="BG_line"
              data-name="BG line"
              d="M1991.7,1524.342s49.684,773.088,1346.735,761.205c387.722,1.023,473.437,43.821,574.585,113.938"
              transform="translate(-2245.473 -749.609) rotate(-11)"
              fill="none"
              stroke="#707070"
              stroke-width="1"
              variants={pathVariants(3)}
            />
          </motion.svg>
        </div>

        {/* 360 view */}
        <div className="Amenities-inner section-360">
          <section
            className="view-360"
            style={{ backgroundImage: `url(${img360})` }}
          >
            {/* <div >
                <img src={img360} />
            </div> */}
            <div className="content_">
              <h4>VIEW EXECUTIVE ROOM 360&#176; WALKTHROUGH</h4>
              <button>
                <Link to={villa.videoUrl} target="_blank">
                  click here
                </Link>
              </button>
            </div>
          </section>
        </div>

        <div ref={sectionRef} className="reviews-section">
          <div>
            <h1>CUSTOMER REVIEWS</h1>
            <div className="review_count-d">
              <span className="reviews-count d-flex justify-content-center align-items-center ">
                <img src={star} width={20} height={20} /> {avgRating} ·{" "}
                {totalReviews || 0} Reviews
              </span>

              <span
                className="write_reviews_btn"
                onClick={() => handleChangeReview("panel1")}
              >
                Write a review
              </span>
            </div>
          </div>

          <div className="contact_sec_three write_review">
            <WriteAReview expanded={expanded} villa={villa} />
          </div>

          {!expanded && (
            <div className="row m-0">
              {reviews?.map((data) => {
                return (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 reviews-s">
                      <div className="review_">
                        <div className="d-flex align-items-center gap-2">
                          <Avatar alt={data.name} src={null} />
                          <div>
                            <span>{data.name}</span>
                            <p className="review_date m-0">
                              {helper.ISTDate(data.createdAt, "DD MMMM YYYY")}
                            </p>
                          </div>
                        </div>
                        <div className="show_reviews_d">
                          <Rating
                            value={data.rating}
                            readOnly
                          // precision={0.5}

                          // emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                          />

                        </div>
                        <div className="review_content content_d">
                          <span>{data.title}</span>
                          <p className="review_s_content">{data.body}</p>
                        </div>

                      </div>
                    </div>
                  </>
                );
              })}

              <div className="text-center">
                {loadMoreVisible && (
                  <button className="show-all" onClick={handleLoadMore}>
                    Load More
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <Modal
        show={modalShow}
        centered
        onHide={() => setModalShow(false)}
        dialogClassName="modal_W"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>

        <section className="contact_sec_three modal_form_d">
          <div className="">
            <motion.div
              className="contact_sec_three_heading"
              variants={textVariants("down", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h1>GET IN TOUCH</h1>
            </motion.div>

            <motion.Form
              className="contact_form"
              onSubmit={handleSubmit}
              autoComplete="off"
              variants={textVariants("right", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <Form.Group className="form-group_ ">
                <Form.Label>FIRST NAME*</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.first_name}</span>
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>LAST NAME*</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.last_name}</span>
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>PHONE*</Form.Label>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Your Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.phone}</span>
              </Form.Group>
              <Form.Group className="form-group_ ">
                <Form.Label>E-MAIL*</Form.Label>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Your e-mail"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.email}</span>
              </Form.Group>
              <Form.Group className="w-100">
                <Form.Label>MESSAGE</Form.Label>
                <TextField
                  variant="standard"
                  placeholder="Enter your message here"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                />
                <span className="err_msg">{formError.message}</span>
              </Form.Group>

              <button className="contact_form_submit_button" type="submit">
                <div
                  className="action active-cursor decor no-transition"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: " none",
                    transform: "translate(0px, 0px)",
                    opacity: "1",
                  }}
                >
                  <p className="m-0">Send Request</p>

                  <CTASvg />
                </div>
              </button>
            </motion.Form>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default SingleVilla;
